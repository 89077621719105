import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  HoldingBoDtoNBK,
  PhaseActionBoDtoNBK,
  PhaseBoDtoNBK,
  PreheatDtoNBK,
  RecipeBoDtoNBK
} from 'src/app/api/nbk';
import {
  deviceConfiguration,
  KEY_LANGUAGE_STORAGE,
  PhaseBoDtoNBKComplete
} from 'src/app/core/utils';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RecipeDetailService } from '../../recipe-detail.service';
import { pairwise } from 'rxjs/operators';
import {
  maintenanceConfiguration,
  PH_COMBI_CONFIG,
  preheatConfiguration
} from 'src/app/core/cooking-step.utils';

interface ITabItem {
  ref: string;
  img: ITabImage;
  label: string;
  sectionList: ISectionList[];
}

interface ITabImage {
  url: string;
  alt: string;
}

export interface ISectionList {
  label: string;
  disabled: boolean;
}

type ActionDto = PhaseActionBoDtoNBK & { name: string, checked: boolean };

@Component({
  selector: 'app-edit-cooking-steps-modal',
  templateUrl: './edit-cooking-steps-modal.component.html',
  styleUrls: ['./edit-cooking-steps-modal.component.scss']
})
export class EditCookingStepsModalComponent implements OnInit, AfterContentInit {

  tabList: ITabItem[];
  phase?: PhaseBoDtoNBKComplete;
  firstPhase?: boolean;
  cookingStepFormGroup: UntypedFormGroup;
  cookingSteps: PhaseBoDtoNBK[];
  preheat?: PreheatDtoNBK;
  holding?: HoldingBoDtoNBK;
  selected: number = 0;
  tempUnit: 'C' | 'F';

  deviceModels = RecipeBoDtoNBK.DeviceModelEnum;

  holdingGroup = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    temperature: new UntypedFormControl(''),
    humidity: new UntypedFormControl('')
  });

  get typeHolding() {
    return this.holdingGroup.get('type') as UntypedFormControl;
  }
  get temperatureHolding() {
    return this.holdingGroup.get('temperature') as UntypedFormControl;
  }

  preheatGroup = new UntypedFormGroup({
    type: new UntypedFormControl(''),
    temperature: new UntypedFormControl(''),
    humidity: new UntypedFormControl('')
  });

  get preheatType() {
    return this.preheatGroup.get('type') as UntypedFormControl;
  }
  get preheatTemperature() {
    return this.preheatGroup.get('temperature') as UntypedFormControl;
  }

  probe1Control = new UntypedFormControl(30);
  probe2Control = new UntypedFormControl(null);
  aromaControl = new UntypedFormControl(0);
  smokeControl = new UntypedFormControl(0);
  comandsControl = new UntypedFormArray([]);

  cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  cookingModeList = RecipeBoDtoNBK.CookingModeEnum;
  deviceModelList = RecipeBoDtoNBK.DeviceModelEnum;

  activeIdString: string;
  preheatMinimuValue: number = PH_COMBI_CONFIG.MIN_VALUE_PREHEAT;
  preheatConfiguration = preheatConfiguration;
  maintenanceConfiguration = maintenanceConfiguration;

  maintementList = [
    {
      value: 0,
      name: 'Off'
    },
    {
      value: 1,
      name: 'Soft'
    },
    {
      value: 2,
      name: 'Medium'
    },
    {
      value: 3,
      name: 'Hard'
    }
  ];

  actionsList: ActionDto[];

  get customActionValue() {
    let customIndex = (this.comandsControl.value as any[]).findIndex((x) => x.type === 'CUSTOM');
    return customIndex >= 0 ? this.comandsControl.value[customIndex].customAction : undefined;
  }

  get cookinglevelpin1() {
    return (this.cookingStepFormGroup.controls['attributes'] as UntypedFormGroup)
      .controls['cookinglevelpin1'].value;
  }
  get cookinglevelpin2() {
    return (this.cookingStepFormGroup.controls['attributes'] as UntypedFormGroup)
      .controls['cookinglevelpin2'].value;
  }

  cookingStepCreated = new EventEmitter<{
    formData: UntypedFormGroup;
    holdingData: UntypedFormGroup;
    preheatData: UntypedFormGroup;
  }>();
  cookingStepsEdited = new EventEmitter<{
    index: number;
    formData: UntypedFormGroup;
    holdingData: UntypedFormGroup;
    preheatData: UntypedFormGroup;
  }>();

  constructor(
    public activeModal: NgbActiveModal,
    public recipeDetailService: RecipeDetailService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeIdString =
      this.cookingMode === this.cookingModeList.Combi
        ? 'CONVECTION'
        : 'MICROWAVE';
    this.deviceModel = this.deviceModel || this.deviceModelList.Oracle;
    this.cookingMode = this.cookingMode || this.cookingModeList.Combi;
    const device = deviceConfiguration[this.deviceModel];

    this.tabList =
      device.find((mode) => mode.coockingMode === this.cookingMode)?.tabList ||
      [];
    
    this.actionsList = this.generateActionsList();

    this.cookingStepFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(),
      actions: this.comandsControl,
      cookingType: new UntypedFormControl(this.activeIdString),
      magnetron: new UntypedFormControl(0),
      ventPreopenTimer: new UntypedFormControl(),
      fanSpeed: new UntypedFormControl(0),
      alternatingFan: new UntypedFormControl(false),
      ventOpen: new UntypedFormControl(),
      smokegrill: this.smokeControl,
      aroma: this.aromaControl,
      temperature: new UntypedFormControl(),
      time: new UntypedFormControl(0),
      deltaT: new UntypedFormControl(false),
      attributes: new UntypedFormGroup({
        vaporType: new UntypedFormControl(),
        gilding: new UntypedFormGroup({
          min: new UntypedFormControl(),
          max: new UntypedFormControl(),
          value: new UntypedFormControl()
        }),
        humidity: new UntypedFormGroup({
          min: new UntypedFormControl(),
          max: new UntypedFormControl(),
          value: new UntypedFormControl()
        }),
        cookinglevelpin1: new UntypedFormGroup({
          min: new UntypedFormControl(),
          max: new UntypedFormControl(),
          value: this.probe1Control
        }),
        cookinglevelpin2: new UntypedFormGroup({
          min: new UntypedFormControl(),
          max: new UntypedFormControl(),
          value: this.probe2Control
        }),
        cooling: new UntypedFormGroup({
          coolingType: new UntypedFormControl(),
          duration: new UntypedFormControl()
        })
      })
    });

    this.recipeDetailService.initPreheatTemperature(
      this.cookingMode,
      this.firstPhase!,
      this.phase!,
      this.preheat!,
      this.tempUnit
    );

    if (this.phase) {
      this.populateActions(this.phase.actions || []);

      this.cookingStepFormGroup.patchValue(this.phase);
      this.activeIdString = this.phase.cookingType || this.activeIdString;
      this.cookingStepFormGroup.controls['cookingType'].setValue(
        this.phase.cookingType
      );
    }

    if (this.holding) {
      this.holdingGroup.patchValue(this.holding);
    }

    if (this.preheat) {
      this.preheat.temperature =
        this.recipeDetailService.getPreheatInitialTemperature();
      this.preheatMinimuValue =
        this.recipeDetailService.getPreheatMinimumValue();
      this.preheatGroup.patchValue(this.preheat);
    }

    this.cookingStepFormGroup
      .get('temperature')!
      .valueChanges.pipe(pairwise())
      .subscribe(([prev, next]) => {
        if (this.firstPhase && prev !== next) {
          this.recipeDetailService.updatePreheatTemperature(
            this.cookingMode,
            next || 0,
            this.tempUnit
          );
          this.preheatMinimuValue =
            this.recipeDetailService.getPreheatMinimumValue();

          setTimeout(() => {
            this.preheat!.temperature =
              this.recipeDetailService.getPreheatInitialTemperature();
            this.preheatGroup.patchValue(this.preheat!);
          }, 0);
        }
      });
  }

  ngAfterContentInit() {
    this.changeDetector.detectChanges();
  }

  navChange(v: any) {
    this.cookingStepFormGroup.controls['cookingType'].setValue(v.nextId);
  }

  saveCookingStep() {
    if (this.phase) {
      this.cookingStepsEdited.next({
        index: this.selected,
        formData: this.cookingStepFormGroup,
        holdingData: this.holdingGroup,
        preheatData: this.preheatGroup
      });
    } else {
      this.cookingStepCreated.next({
        formData: this.cookingStepFormGroup,
        holdingData: this.holdingGroup,
        preheatData: this.preheatGroup
      });
    }
  }

  setAction(a: any, type: string) {
    let currentFormValue: any[] = this.comandsControl.value;
    this.actionsList.forEach( e => e.checked = false );
    this.comandsControl.clear();
    
    if (a.currentTarget.checked) {
      if ( type !== 'CUSTOM' ) {
        this.removeCommand(currentFormValue,'CUSTOM');
      }

      this.comandsControl.push(
        new UntypedFormGroup({
          type: new UntypedFormControl(type),
          customAction: new UntypedFormControl(undefined)
        })
      );
      this.actionsList.filter( a => a.type === type ).forEach( a => a.checked = true );
    } else if ( currentFormValue.length > 1 ) {
      this.removeCommand(currentFormValue,type);
    }
  }

  private removeCommand (currentFormValue: any[], type: string) {
    let customIndex = currentFormValue.findIndex((x) => x.type === type);
    if (customIndex > -1) {
      currentFormValue.splice(customIndex, 1);
    }

    for (let e of currentFormValue) {
      this.actionsList.filter( a => a.type === e.type ).forEach( a => a.checked = true );
      this.comandsControl.push(
        new UntypedFormGroup({
          type: new UntypedFormControl(e.type),
          customAction: new UntypedFormControl(e.customAction)
        })
      );
    }
  }

  setCustomActionValue(event: any) {
    let customIndex = (this.comandsControl.value as any[]).findIndex((x) => x.type === 'CUSTOM');
    this.comandsControl.get(`${customIndex}`)?.get('customAction')?.patchValue(event.currentTarget.value);
  }

  selectStep(stepIndex: number) {
    this.selected = stepIndex;
    this.cookingStepFormGroup.patchValue(this.cookingSteps[stepIndex]);
    this.populateActions(this.cookingSteps[stepIndex].actions || []);
    this.activeIdString =
      this.cookingStepFormGroup.controls['cookingType'].value;
  }

  populateActions(actions: PhaseActionBoDtoNBK[]): void {
    const newActionList = this.generateActionsList();

    this.actionsList = [];

    this.comandsControl.controls = [];
    actions?.forEach((action) => {
      const index = newActionList.findIndex((a) => a.type === action.type);

      if (index !== -1) {
        newActionList[index].checked = true;
        this.comandsControl.push(
          new UntypedFormGroup({
            customAction: new UntypedFormControl(action.customAction),
            type: new UntypedFormControl(action.type)
          })
        );
      }
    });

    this.actionsList = newActionList.map((action) => action);
  }

  generateActionsList() {
    const newActionList = [] as ActionDto[];

    Object.keys(PhaseActionBoDtoNBK.TypeEnum).forEach( e => {
      const type = e.toUpperCase();
      newActionList.push({
        type: type as PhaseActionBoDtoNBK.TypeEnum,
        name: `RECIPE.PHASE.ACTION.${type}`,
        checked: false
      })
    });

    return newActionList;
  }

  showCustomCommandTextArea() {
    return this.comandsControl.value.map( (e: any) => e.type).includes('CUSTOM');
  }

  onInitPreheat() {
    this.recipeDetailService.initPreheatTemperature(
      this.cookingMode,
      this.firstPhase!,
      this.phase!,
      this.preheat!,
      this.tempUnit
    );
    this.preheat!.temperature =
      this.recipeDetailService.getPreheatInitialTemperature();
    this.preheatMinimuValue = this.recipeDetailService.getPreheatMinimumValue();
  }

}
