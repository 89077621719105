<div class="modal-header">
  <h2>
    {{
      (!phase
        ? 'COOKINGSTEP.CREATE_COOKING_STEP'
        : 'COOKINGSTEP.EDIT_COOKING_STEP'
      ) | translate
    }}
  </h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body px-3" [formGroup]="cookingStepFormGroup">
  <ng-container *ngIf="phase">
    <div id="step-list" *ngIf="cookingSteps.length !== 0">
      <button
        *ngFor="let item of cookingSteps; let i = index"
        [ngClass]="
          selected === i ? 'button-tertiary' : 'button-outline-tertiary'
        "
        (click)="selectStep(i)"
      >
        {{ 'COOKINGSTEP.STEP' | translate }} {{ (i+1) }}
      </button>
    </div>
  </ng-container>
  <div class="row">
    <ng-container *ngIf="cookingMode === 'COMBI'">
      <div class="col col-md-6">
        <ul
          id="tabs"
          ngbNav
          #nav="ngbNav"
          [activeId]="activeIdString"
          (navChange)="navChange($event)"
          [destroyOnHide]="false"
          class="nav-tabs"
        >
          <ng-container *ngFor="let tab of tabList">
            <li [ngbNavItem]="tab.ref" [destroyOnHide]="true">
              <a ngbNavLink>
                <img [src]="tab.img.url" [alt]="tab.img.alt" />
                <span> {{ tab.label }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-phase-tab
                  [deviceModel]="deviceModel"
                  [cookingMode]="cookingMode"
                  [sectionList]="tab.sectionList"
                  [fgroup]="cookingStepFormGroup"
                  [steam]="tab.ref === 'STEAM'"
                  [tabType]="tab.ref"
                  [tempUnit]="tempUnit"
                  [firstPhase]="firstPhase!"
                  (initPreheat)="onInitPreheat()"
                ></app-phase-tab>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <div id="tabs-content" [ngbNavOutlet]="nav"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="cookingMode === 'HSO'">
      <div class="col col-md-6">
        <app-phase-tab-hso
          [deviceModel]="deviceModel"
          [cookingMode]="cookingMode"
          [fgroup]="cookingStepFormGroup"
          [tempUnit]="tempUnit"
        ></app-phase-tab-hso>
      </div>
    </ng-container>
    <div class="col col-md-6">
      <ul id="plus-box">
        <li class="recipe-field rounded-top">
          <div class="plus-single-el">
            <span class="main-label">{{
              'COOKINGSTEP.PREHEAT' | translate
            }}</span>
          </div>
          <ng-container *ngIf="recipeDetailService.preheatDisabled">
            <app-slider
              [control]="preheatTemperature"
              [floor]="preheatMinimuValue"
              [ceil]="preheatConfiguration(cookingMode, tempUnit).ceil"
              [color]="'red'"
              [temperature]="true"
              [tempUnit]="tempUnit"
              [showButton]="true"
              [step]="1"
            ></app-slider>
          </ng-container>
          <ng-container *ngIf="!recipeDetailService.preheatDisabled">
            {{ 'GLOBAL.NULL_VALUE' | translate }}
          </ng-container>
        </li>
        <li class="recipe-field">
          <div class="plus-single-el">
            <span class="main-label">{{
              'COOKINGSTEP.MAINTENANCE' | translate
            }}</span>
            <ng-select
              [formControl]="typeHolding!"
              [items]="maintementList"
              classList="custom-input"
              bindValue="name"
              bindLabel="name"
              [clearable]="false"
              [searchable]="false"
            ></ng-select>
          </div>
          <app-slider
            *ngIf="typeHolding.value !== 'Off'"
            [control]="temperatureHolding"
            [floor]="maintenanceConfiguration(tempUnit).floor"
            [ceil]="maintenanceConfiguration(tempUnit).ceil"
            [color]="'red'"
            [showButton]="true"
            [step]="1"
            [tempUnit]="tempUnit"
            [temperature]="true"
          ></app-slider>
        </li>
        <li>
          <div>
            <div class="plus-single-el align-items-start">
              <span class="main-label">{{
                'COOKINGSTEP.COMMANDS' | translate
              }}</span>
              <span class="command-list" *ngIf="actionsList.length > 0">
                <ng-container *ngFor="let action of actionsList">
                  <label for="{{ action.type }}"
                    ><input
                      type="checkbox"
                      name="commands"
                      id="{{ action.type }}"
                      [value]="action.type"
                      [checked]="action.checked"
                      (click)="setAction($event, action.type!)"
                    />
                    {{ action.name! | translate }}</label
                  ><br />
                </ng-container>
              </span>
            </div>
            <label class="custom-label" *ngIf="showCustomCommandTextArea()">
              <textarea
                class="custom-input"
                rows="3"
                [value]="customActionValue"
                (change)="setCustomActionValue($event)"
              ></textarea>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    [disabled]="!cookingStepFormGroup.valid"
    (click)="saveCookingStep()"
    class="button-primary"
  >
    {{ 'GLOBAL.SAVE' | translate }}
  </button>
</div>
