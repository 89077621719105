<div class="device-card">
  <div class="device-menu">
    <img *ngIf="device.online && device.owner; else elseWifiIcon" class="wifi-user" src="assets/images/wifi-user.svg" />
    <ng-template #elseWifiIcon>
      <i  class="fas fa-wifi status" [ngClass]="device.online ? 'online' : 'offline'"></i>
    </ng-template>
    <app-device-action
      [device]="device"
      [kcUser]="kcUser"
      (update)="loadDataSource()"
    ></app-device-action>
  </div>
  <ul>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.name)">
      <label>{{ 'DEVICES.DEVICE_CARD.NAME' | translate }}</label>
      <span>{{ device.name || '--' }}</span>
    </li>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.hardware)">
      <label>{{ 'DEVICES.DEVICE_CARD.HARDWARE_ID' | translate }}</label>
      <span>{{ device.hardwareId || '--' }}</span>
    </li>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.serial)">
      <label>{{ 'DEVICES.DEVICE_CARD.SERIAL_NUMBER' | translate }}</label>
      <span>{{ device.serial || '--' }}</span>
    </li>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.firmware)">
      <label>{{ 'DEVICES.DEVICE_CARD.FIRMWARE' | translate }}</label>
      <span>{{ device.firmware || '--' }}</span>
    </li>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.model)">
      <label>{{ 'DEVICES.DEVICE_CARD.MODEL' | translate }}</label>
      <span>{{ device.model || '--' }}</span>
    </li>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.sync)">
      <label>{{ 'DEVICES.DEVICE_CARD.SINC' | translate }}</label>
      <span *ngIf="device.lastSync">{{
        device.lastSync | fromUTCToLocale | date: 'medium':timezone:lang
      }}</span>
      <span *ngIf="!device.lastSync">--</span>
    </li>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.date) && device.owner === kcUser.id">
      <label>
        {{ 'DEVICES.DEVICE_CARD.REGISTRATION_DATE' | translate }}
      </label>
      <ng-container *ngIf="device.currentOwnerFirstAssociation">
        <span>
          {{
            device.currentOwnerFirstAssociation
              | fromUTCToLocale
              | date: 'medium':timezone:lang
          }}
        </span>
      </ng-container>
      <ng-container *ngIf="!device.currentOwnerFirstAssociation">
        <span> -- </span>
      </ng-container>
    </li>
    <li *ngIf="checkIfViewInfo(InfoDeviceEnum.display) && device.owner === kcUser.id">
      <label>{{ 'MY_DISPLAY.DISPLAY' | translate }}</label>
      <ng-container>
        <ul class="display-list">  
          <li *ngFor="let display of device.displays">  
            <span>{{display.name}}</span>  
          </li> 
        </ul>
      </ng-container>
    </li>
  </ul>
</div>
