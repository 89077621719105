<div ngbDropdown class="d-inline-block activator">
  <i ngbDropdownToggle class="fa fa-ellipsis-v"></i>
  <div ngbDropdownMenu>
    <button
      ngbDropdownItem
      (click)="changeName()"
      *ngIf="device.owner === kcUser.id"
    >
      {{ 'DEVICES.DEVICE_CARD.ACTIONS.EDIT' | translate }}
    </button>
    <button ngbDropdownItem (click)="showTelemetry()">
      {{ 'TELEMETRY.TYPE.HACCP' | translate }}
    </button>
    <button ngbDropdownItem (click)="showStatistics()">
      {{ 'DEVICE.STATISTICS.TITLE' | translate }}
    </button>
    <button
      ngbDropdownItem
      (click)="showParameter()"
      *ngIf="authService.permissions.SUPERUSER"
    >
      {{ 'DEVICES.DEVICE_CARD.ACTIONS.PARAMETER' | translate }}
    </button>
    <button
      ngbDropdownItem
      (click)="handleAssignDisplay()"
      *ngIf="device.owner === kcUser.id && kcUser.multiDisplay"
    >
      {{ 'DEVICES.DEVICE_CARD.ACTIONS.CHANGE_DISPLAY' | translate }}
    </button>
    <button
      ngbDropdownItem
      (click)="unpairDevice()"
      *ngIf="device.owner === kcUser.id"
    >
      {{ 'DEVICES.DEVICE_CARD.ACTIONS.UNPAIR' | translate }}
    </button>
  </div>
</div>

