<div class="modal-header">
  <h2>{{ 'DEVICES.DEVICE_CARD.MODALS.PARAMETER.TITLE' | translate }}</h2>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="row">
    <div class="col d-flex justify-content-end mb-3">
      <button class="button-primary mx-2" (click)="getParams()">
        <i class="fa fa-sync-alt"></i> Aggiorna
      </button>
      <button class="button-primary" (click)="saveParams()">
        <i class="fa fa-save"></i> Salva parametri
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-datatable
        [dataSource]="dataSource"
        [columns]="columns"
      ></app-datatable>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="button-secondary" (click)="activeModal.dismiss('Close click')">
    {{ 'GLOBAL.CLOSE' | translate }}
  </button>
</div>

<ng-template #valueTpl let-row="row">
  <input
    type="number"
    class="custom-input"
    [(ngModel)]="row.value"
    [disabled]="isSavingParams"
  />
</ng-template>

<!-- 
    <ng-template #ellipsisTpl let-row="row">
    <div [title]="row.data" class="ellipsis">{{ row.data }}</div>
  </ng-template> -->
