import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import {
  AccessoryBoDtoNBK,
  ImageService,
  IngredientBoDtoNBK,
  NationDtoNBK,
  RecipeBoDtoNBK,
  RecipeService,
  RecipeTypeDtoNBK,
  ResultSetTagDtoNBK,
  StepBoDtoNBK,
  StepIngredientDtoNBK,
  TagDtoNBK,
  TagService
} from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import {
  checkFormValidity,
  getCookingModeList,
  getDeviceModelList,
  KEY_LANGUAGE_STORAGE,
  languagesList,
  SelectableCookingMode,
  SelectableDeviceModel
} from 'src/app/core/utils';
import { IngredientsService } from './ingredients/ingredients.service';
import { RecipesListDataService } from '../recipes/recipes-list-data.service';
import { RecipeDetailService } from './recipe-detail.service';
import {
  convertRecipeToCelsius,
  convertRecipeToFahrenheit,
  TTemperatureUnit
} from 'src/app/core/cooking-step.utils';
import { prefixTranslationICS } from 'src/app/core/recipe.utils';
import { TwoBtnModalState } from '../modals/two-btn-modal/two-btn-modal.component';
import { ModalService } from '../modals/modal.service';
import { CookingStepsComponent } from './cooking-steps/cooking-steps.component';
import { canHandleIcsItem, canMarkAsIcs } from 'src/app/core/permission.utils';

export type ImageRecipes = {
  file: File | Blob;
  alt?: string;
  accessory_id?: string;
  recipe_id?: string;
  step_id?: string;
  id?: string;
};

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.scss']
})
export class RecipeDetailComponent implements OnInit, OnDestroy {
  recipe: RecipeBoDtoNBK;
  isEditing = false;
  isCreate = false;
  isLainox = false;
  newRecipeName: string | null;
  tempUnit: TTemperatureUnit;
  deviceModelList: SelectableDeviceModel[];
  cookingModeList: SelectableCookingMode[];
  recipeTypeList: RecipeTypeDtoNBK[];
  countryList: NationDtoNBK[];
  languageList = languagesList;
  ingredient: IngredientBoDtoNBK[] = [];
  recipeIconUrl?: string;
  recipeImageUrl?: string;

  tags$: Observable<TagDtoNBK[]>;
  tagsInput$ = new Subject<string>();
  unsubscribe$ = new Subject<void>();
  createRecipe$ = new BehaviorSubject<RecipeBoDtoNBK |undefined>(undefined);

  recipeFormGroup: UntypedFormGroup;
  previewFormGroup: UntypedFormGroup;
  stepsArrayControl = new UntypedFormArray([]);
  languageControl = new UntypedFormControl(
    localStorage.getItem(KEY_LANGUAGE_STORAGE)!
  );

  get stepArrayControl() {
    return this.recipeFormGroup.controls['steps'] as UntypedFormArray;
  }

  @ViewChild('cookingStepsComponentRef') cookingStepsComponentRef: CookingStepsComponent;
  constructor(
    private ts: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private recipeService: RecipeService,
    private ingredientsService: IngredientsService,
    private authService: AuthService,
    private imageService: ImageService,
    private tagService: TagService,
    private ngLocation: Location,
    private listDataService: RecipesListDataService,
    private recipeDetailService: RecipeDetailService,
    private modalButtonService: ModalService
  ) {
    this.deviceModelList = getDeviceModelList();
    //TODO to review when enabled other device management
    this.cookingModeList = this.getCookingModeList('ORACLE');

    this.listDataService.getRecipeTypeList$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.recipeTypeList = [...(res || [])];
        })
      )
      .subscribe();
    this.listDataService.getRecipeTypeList();

    this.listDataService.getCountryList$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((res) => {
          this.countryList = [...(res || [])];
        })
      )
      .subscribe();
    this.listDataService.getCountryList();

  }

  ngOnInit(): void {
    this.ingredientsService.getIngredients();
    this.ingredientsService.ingredients$.subscribe(
      (ingr: IngredientBoDtoNBK[]) => {
        this.ingredient = ingr;
      }
    );

    this.recipe = this.route.snapshot.data.recipeData?.recipe;
    this.tempUnit = this.route.snapshot.data.recipeData?.tempUnit;
    this.isLainox = this.route.snapshot.data.isLainox;
    this.newRecipeName = this.route.snapshot.queryParamMap.get('name');

    if (!this.newRecipeName) {
      this.isCreate = true;
    }

    this.previewFormGroup = new UntypedFormGroup({
      context: new UntypedFormControl(),
      previewIngredients: new UntypedFormControl(),
      previewStep: new UntypedFormControl(),
      previewAccessories: new UntypedFormControl()
    });

    this.generateRecipeForm();
    if (this.recipe) {
      this.patchRecipe();
      this.recipeDetailService.initPreheatTemperature(
        this.recipe.cookingMode!,
        true,
        this.recipe.phases && this.recipe.phases?.length > 0
          ? this.recipe.phases[0]
          : null,
        this.recipe.preheat!,
        this.tempUnit
      );
    } else {
      this.isEditing = true;
      this.previewFormGroup.enable();
    }

    this.recipeFormGroup
      .get('deviceModel')
      ?.valueChanges.subscribe(
        (deviceModel: RecipeBoDtoNBK.DeviceModelEnum) => {
          this.cookingModeList = this.getCookingModeList(deviceModel);

          if (!this.recipeFormGroup.get('cookingMode')?.value) {
            this.recipeFormGroup
              .get('cookingMode')
              ?.setValue(this.cookingModeList[0].id);
          }
        }
      );

    this.recipeFormGroup
      .get('cookingMode')
      ?.valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        ).subscribe(
        (cookingMode: RecipeBoDtoNBK.CookingModeEnum) => {
          const oldVal: RecipeBoDtoNBK.CookingModeEnum = this.recipeFormGroup.value['cookingMode'];
          if ( cookingMode !== oldVal && this.recipeFormGroup.controls['phases'].value.length > 0 ) {
            this.changeCookingMode(oldVal);
          }
        }
      );

    this.loadTags();

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getCookingModeList(
    deviceModel: RecipeBoDtoNBK.DeviceModelEnum
  ): SelectableCookingMode[] {
    return getCookingModeList(deviceModel);
  }

  saveRecipe() {
    if (checkFormValidity(this.recipeFormGroup)) {
      let recipe = {
        ...this.recipeFormGroup.value,
        ...this.previewFormGroup.value
      };
  
      delete recipe.ingredients;
      delete recipe.previews;
      delete recipe.type;
      recipe.images = [];
      recipe.icon = {};
      recipe.steps?.forEach((s: any) => (s.images = []));
  
      !recipe.typeId || recipe.typeId === 0 ? (recipe.typeId = null) : null;
  
      this.tempUnit === 'F' ? convertRecipeToCelsius(recipe) : null;
  
      this.recipeFormGroup.disable();
      this.previewFormGroup.disable();

      if (!this.recipe) {
        this.createRecipe$
        .pipe(
          takeUntil(this.unsubscribe$),
          filter((recipe) => recipe !== undefined),
          switchMap((recipe) =>
            this.recipeService.insertRecipe(
              this.languageControl.value,
              recipe
            )
          ),
          switchMap((recipeCreated) => {
            const imagesRecipe = [
              ...this.recipeFormGroup.controls['images'].value
            ];
    
            if (imagesRecipe && imagesRecipe.length > 0) {
    
              const img: ImageRecipes = {
                file: imagesRecipe[0].file,
                alt: this.createRecipe$.value!.name,
                recipe_id: recipeCreated.id!.toString()
              };
              
              this.imageService.insertImage(
                img.file,
                img.alt,
                img.accessory_id,
                img.recipe_id,
                img.step_id
              ).subscribe();
            } 
    
            return of(recipeCreated);
          }),
          switchMap((recipeCreated) => {
            if (this.recipeFormGroup.controls['icon'].value.file) {
              const iconImage: ImageRecipes = {
                file: this.recipeFormGroup.controls['icon'].value.file,
                alt: this.createRecipe$.value!.name,
                recipe_id: recipeCreated.id!.toString()
              };
    
              this.recipeService.createRecipeIcon(
                +iconImage.recipe_id!,
                iconImage.file
              ).subscribe();
            } 
    
            return of(recipeCreated);
          }),
          tap((recipeUpdated) => {
            this.recipeFormGroup.controls['steps'].value.forEach(
              (step: any, indexStep: number) => {
                step.images.forEach(async (i: any, index: number) => {
                  const img: ImageRecipes = {
                    file: i.file,
                    alt: recipeUpdated.steps![indexStep].name,
                    step_id: recipeUpdated.steps![indexStep].id?.toString()
                  };
                  await this.imageService
                    .insertImage(
                      img.file,
                      img.alt,
                      img.accessory_id,
                      img.recipe_id,
                      img.step_id
                    )
                    .pipe(
                      take(1),
                      tap(() =>
                        (
                          this.recipeFormGroup.controls['images'] as UntypedFormArray
                        ).removeAt(index)
                      )
                    )
                    .toPromise();
                });
              }
            );
          }),
          tap(() => {
            this.toastr.success(
              this.ts.instant('RECIPE.RECIPE_CREATED'),
              this.ts.instant('GLOBAL.SUCCESS')
            );
    
            setTimeout(() => {
              this.router.navigate(['my-naboo']);
            }, 500);
          }),
          catchError((err) => {
            this.recipeFormGroup.enable();
            this.previewFormGroup.enable();
            return of({});
          }),
          take(1)
        )
        .subscribe();
        this.createRecipe$.next(recipe);
      } else {
        this.isEditing = false;
        this.updateRecipe(recipe);
      }
    }
    
  }

  private updateRecipe(recipe: any) {
    this.recipeService.updateRecipe(this.languageControl.value, recipe)
    .pipe(
      takeUntil(this.unsubscribe$),
      switchMap((recipeUpdated) => {
        const imagesRecipe = [
          ...this.recipeFormGroup.controls['images'].value
        ];
        if (imagesRecipe && imagesRecipe.length > 0) {
          const img: ImageRecipes = {
            file: imagesRecipe[0].file,
            alt: recipeUpdated!.name,
            recipe_id: recipeUpdated.id!.toString()
          };

          if (img.file) {
            this.imageService.insertImage(
              img.file,
              img.alt,
              img.accessory_id,
              img.recipe_id,
              img.step_id
            ).subscribe();
          }
        }

        return of(recipeUpdated);
      }),
      switchMap((recipeUpdated) => {
        if (this.recipeFormGroup.controls['icon'].value.file) {
          const iconImage: ImageRecipes = {
            file: this.recipeFormGroup.controls['icon'].value.file,
            alt: recipeUpdated!.name,
            recipe_id: recipeUpdated.id!.toString()
          };

          if (iconImage.file) {
            this.recipeService.createRecipeIcon(
              +iconImage.recipe_id!,
              iconImage.file
            ).subscribe();
          }
        }

        return of(recipeUpdated);

      }),
      switchMap((recipeUpdated) => {
        this.recipeFormGroup.controls['steps'].value.forEach(
          (step: any, indexStep: number) => {
            step.images.forEach(async (i: any, index: number) => {
              const img: ImageRecipes = {
                file: i.file,
                alt: recipeUpdated.steps![indexStep].name,
                step_id: recipeUpdated.steps![indexStep].id?.toString()
              };

              img.file
                ? await this.imageService
                  .insertImage(
                    img.file,
                    img.alt,
                    img.accessory_id,
                    img.recipe_id,
                    img.step_id
                  )
                  .pipe(
                    take(1),
                    tap(() => (
                      this.recipeFormGroup.controls['images'] as UntypedFormArray
                    ).removeAt(index)
                    )
                  )
                  .toPromise()
                : null;
            });
          }
        );

        return of(recipeUpdated);
      }),
      tap((recipeUpdated) => {
        this.toastr.success(
          this.ts.instant('RECIPE.RECIPE_UPDATED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );

        this.refreshData(recipeUpdated);
      })
    )
    .subscribe();
  }

  refreshData(recipeUpdated: RecipeBoDtoNBK) {
    this.recipe = recipeUpdated;
    this.recipeFormGroup.patchValue(recipeUpdated); 
  }

  editRecipe() {
    this.isEditing = true;
    this.recipeFormGroup.enable();
    this.previewFormGroup.enable();
  }

  imageIconChanged(image: any) {
    if (!image) {
      if (this.isEditing) {
        this.removeIconImage();
      }
      this.recipeFormGroup.controls['icon'].reset();
    } else {
      this.recipeFormGroup.controls['icon'].setValue({
        alt: this.recipeFormGroup.controls['name'].value,
        file: image,
        id: '',
        url: ''
      });
    }
  }

  imageChanged(file: File | Blob | null) {
    let oldId;
    if (
      this.recipeFormGroup.controls['images'].value &&
      this.recipeFormGroup.controls['images'].value.length > 0
    ) {
      oldId = this.recipeFormGroup.controls['images'].value[0].id;
    }

    if (!file && oldId) {
      if (this.isEditing) {
        this.removeImage(oldId);
      }
    }

    (this.recipeFormGroup.controls['images'] as UntypedFormArray).clear();
    (this.recipeFormGroup.controls['images'] as UntypedFormArray).push(
      new UntypedFormGroup({
        alt: this.recipeFormGroup.controls['name'],
        file: new UntypedFormControl(file),
        id: new UntypedFormControl('')
      })
    );
  }

  updatePreview() {
    let ingredients = '';
    this.stepsArrayControl.value.forEach(
      (element: StepBoDtoNBK, index: number) => {
        ingredients += `${element.name}\n`;
        element.stepIngredient?.forEach((el: StepIngredientDtoNBK) => {
          ingredients += `${el.quantity}${el.um} - ${
            this.ingredient.find((x) => x.id === el.ingredientId)?.name || '--'
          } \n`;
        });
      }
    );
    this.previewFormGroup.controls['previewIngredients'].setValue(ingredients);

    let process = '';
    this.stepsArrayControl.value.forEach((element: any, index: number) => {
      process += `${element.name}\n${element.description} \n\n`;
    });
    this.previewFormGroup.controls['previewStep'].setValue(process);

    let accessories = '';
    this.recipeFormGroup.controls['accessories'].value.forEach(
      (element: AccessoryBoDtoNBK, index: number) => {
        accessories += `${element.name} - ${element.description} \n`;
      }
    );
    this.previewFormGroup.controls['previewAccessories'].setValue(accessories);
  }

  canEdit() {
    return canHandleIcsItem(this.recipe, this.authService);
  }

  canMarkAsIcs() {
    return canMarkAsIcs(this.recipe, this.authService);
  }

  markAsIcs() {
    this.recipeService
      .markIcsRecipe(this.recipe.id!)
      .pipe(
        take(1),
        tap((recipeUpdated) => {
          this.toastr.success(
            this.ts.instant('RECIPE.RECIPE_MARKED_AS_ICS', {
              ICS: this.getInstantICSTranslation()
            }),
            this.ts.instant('GLOBAL.SUCCESS')
          );

          this.ingredientsService.getIngredients();
          this.refreshData(recipeUpdated);
        })
      )
      .subscribe();
  }

  removeIconImage() {
    this.recipeService
      .deleteRecipeIcon(this.recipe.id!)
      .pipe(take(1))
      .subscribe();
  }

  removeImage(id: number) {
    this.imageService.deleteImage(id).pipe(take(1)).subscribe();
  }

  goBack() {
    this.ngLocation.back();
  }

  resetPreheat() {
    this.recipeFormGroup.controls['preheat'].reset();
    this.recipeFormGroup.controls['holding'].reset();
    this.recipeFormGroup.controls['holding'].setValue({
      temperature: null,
      type: 'Off',
      humidity: null
    });
  }

  private loadTags() {
    this.tags$ = concat(
      of([]),
      this.tagsInput$.pipe(
        distinctUntilChanged(),
        switchMap((term) => {
          return this.tagService
            .getAllTags(
              localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
              10,
              undefined,
              undefined,
              undefined,
              `contains(name, '${term}')`
            )
            .pipe(
              take(1),
              map((tags: ResultSetTagDtoNBK) => tags.value!)
            );
        })
      )
    );
  }

  private patchRecipe() {
    this.tempUnit === 'F' ? convertRecipeToFahrenheit(this.recipe) : null;

    (this.recipe.steps || []).forEach((element) => {
      const stepFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl('', Validators.required),
        description: new UntypedFormControl(''),
        stepIngredient: new UntypedFormArray([]),
        images: new UntypedFormArray([]),
        creationLanguage: new UntypedFormControl('')
      });

      (element.stepIngredient || [])?.forEach(() => {
        const ingredientFormGroup = new UntypedFormGroup({
          ingredientId: new UntypedFormControl(null),
          ingredientName: new UntypedFormControl('', Validators.required),
          quantity: new UntypedFormControl('', Validators.required),
          um: new UntypedFormControl('', Validators.required)
        });

        (<UntypedFormArray>stepFormGroup.controls['stepIngredient']).push(
          ingredientFormGroup
        );
      });

      const imageFormGroup = new UntypedFormGroup({
        alt: new UntypedFormControl(''),
        id: new UntypedFormControl(''),
        url: new UntypedFormControl('')
      });

      (<UntypedFormArray>stepFormGroup.controls['images']).push(imageFormGroup);

      this.stepsArrayControl.push(stepFormGroup);
    });

    this.recipe.accessories?.forEach((accessory) => {
      (<UntypedFormArray>this.recipeFormGroup.controls['accessories']).push(
        new UntypedFormControl(accessory)
      );
    });

    this.recipe.phases?.forEach((phase) => {
      (<UntypedFormArray>this.recipeFormGroup.controls['phases']).push(
        new UntypedFormControl(phase)
      );
    });

    this.recipe.images?.forEach((image) => {
      (<UntypedFormArray>this.recipeFormGroup.controls['images']).push(
        new UntypedFormControl(image)
      );
    });

    this.previewFormGroup.patchValue(this.recipe);
    this.recipeFormGroup.patchValue(this.recipe);
    this.recipeFormGroup.disable();

    //Link the url images
    this.recipeIconUrl = this.recipe.icon?.url
      ? this.recipe.icon?.url
      : undefined;
    this.recipeImageUrl =
      this.recipe.images && this.recipe.images.length > 0
        ? this.recipe.images?.filter((i) => i.recipe === this.recipe.id)[0].url!
        : undefined;

    this.previewFormGroup.disable();
  }

  private generateRecipeForm() {
    this.recipeFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      name: new UntypedFormControl(this.newRecipeName),
      //cost not provided
      accessories: new UntypedFormArray([]),
      tip: new UntypedFormControl(null),
      country: new UntypedFormControl('ALL_'),
      tags: new UntypedFormControl([]),
      icon: new UntypedFormGroup({
        alt: new UntypedFormControl(),
        file: new UntypedFormControl(),
        id: new UntypedFormControl(''),
        url: new UntypedFormControl('')
      }),
      images: new UntypedFormArray([]),
      kcal: new UntypedFormControl(''),
      pieces: new UntypedFormControl('', [Validators.pattern('[0-9]*')]),
      time: new UntypedFormControl('', [Validators.pattern('[0-9]*')]),
      cookingMode: new UntypedFormControl('COMBI'),
      typeId: new UntypedFormControl(1),
      ics: new UntypedFormControl(this.isLainox),
      version: new UntypedFormControl(''),
      lastUpdate: new UntypedFormControl(null),
      editable: new UntypedFormControl(''),
      dirtLevel: new UntypedFormControl(''),
      preheat: new UntypedFormGroup({
        temperature: new UntypedFormControl(),
        type: new UntypedFormControl('Off'),
        humidity: new UntypedFormControl()
      }),
      holding: new UntypedFormGroup({
        temperature: new UntypedFormControl(),
        type: new UntypedFormControl('Off'),
        humidity: new UntypedFormControl()
      }),
      steps: this.stepsArrayControl,
      phases: new UntypedFormArray([]),
      user: new UntypedFormControl(''),
      //TODO to remove when enabled other device management
      deviceModel: new UntypedFormControl('ORACLE'),
      finish: new UntypedFormGroup({
        min: new UntypedFormControl(''),
        max: new UntypedFormControl(''),
        value: new UntypedFormControl(0)
      }),
      finishVent: new UntypedFormControl('Off'),
      idUnique: new UntypedFormControl(''),
      creationLanguage: new UntypedFormControl(
        localStorage.getItem(KEY_LANGUAGE_STORAGE)!
      )
    });
  }

  getInstantICSTranslation() {
    return this.ts.instant(
      `${prefixTranslationICS}.${this.recipe.cookingMode}`
    );
  }

  changeCookingMode(oldValue: RecipeBoDtoNBK.CookingModeEnum) {
    const modalInfo: TwoBtnModalState = {
      title: this.ts.instant('GLOBAL.WARNING'),
      message: this.ts.instant('RECIPE.RESET_COOKING_STEP'),
      confirmBtnClass: 'button-danger',
      confirmBtnText: this.ts.instant('GLOBAL.CONTINUE'),
      cancelBtnClass: 'button-secondary',
      cancelBtnText: this.ts.instant('GLOBAL.BACK'),
      preventClose: true
    };

    this.modalButtonService.showTwoBtnModal(modalInfo).subscribe((r) => {
      if (r) {
        (this.recipeFormGroup.get("phases") as UntypedFormArray).clear();
        this.cookingStepsComponentRef.clearCookingStep();
      } else {
        this.recipeFormGroup.get("cookingMode")?.setValue(oldValue, {emitEvent:false});
      }
    });
  }

}
