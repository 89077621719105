<div class="d-flex align-items-center mb-2" [formGroup]="recipeFormGroup">
  <app-upload-image
    [isEditing]="isEditing"
    [imageUrl]="recipeIconUrl"
    otherId="recipe-image"
    (imageChangedBlob)="imageIconChanged($event)"
  ></app-upload-image>
  <ng-container *ngIf="!isEditing">
    <h1 class="m-0 ms-3 ">
      {{
        recipe
          ? recipe.name
          : newRecipeName || ('RECIPE.NEW_RECIPE' | translate)
      }}
    </h1>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <input
      type="text"
      class="custom-input w-100"
      formControlName="name"
      [placeholder]="'RECIPE.NAME' | translate"
    />
  </ng-container>
</div>
<h2>
  {{ 'RECIPE.INFO' | translate }}
  <div class="button-box">
    <button *ngIf="isEditing" class="button-primary" (click)="saveRecipe()">
      <i class="fa fa-save"></i> {{ 'GLOBAL.SAVE' | translate }}
    </button>
    <button *ngIf="!isEditing" class="button-primary" (click)="goBack()">
      <i class="fa fa-chevron-left"></i> {{ 'GLOBAL.BACK' | translate }}
    </button>
    <button
      *ngIf="!isEditing && canMarkAsIcs()"
      class="button-danger"
      (click)="markAsIcs()"
    >
      <i class="fa fa-arrow-up"></i>
      <span>
        {{ 'RECIPE.MARK_ICS' | translate: { ICS: getInstantICSTranslation() } }}
      </span>
    </button>
    <button
      *ngIf="!isEditing && canEdit()"
      class="button-primary"
      (click)="editRecipe()"
    >
      <i class="fa fa-pen"></i> {{ 'GLOBAL.EDIT' | translate }}
    </button>
    <button *ngIf="!isEditing && false" class="button-primary">
      <i class="fa fa-print"></i> {{ 'GLOBAL.PRINT' | translate }}
    </button>
  </div>
</h2>
<div [formGroup]="recipeFormGroup">
  <div class="row align-items-start">
    <div class="col-12 col-sm-7 col-md-8">
      <ul class="info-list">
        <li>
          <label class="main-label">{{
            'RECIPE.DEVICE_MODEL' | translate
          }}</label>
          <ng-select
            [items]="deviceModelList"
            formControlName="deviceModel"
            classList="custom-input"
            bindValue="id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'RECIPE.DEVICE_MODEL' | translate"
            [readonly]="true"
          >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              {{ item.name | translate }}
            </ng-template>
          </ng-select>
          <app-control-errors
            id="deviceModel"
            controlName="deviceModel"
          ></app-control-errors>
        </li>
        <li>
          <label class="main-label">{{
            'RECIPE.COOKING_MODE' | translate
          }}</label>
          <ng-select
            [items]="cookingModeList"
            formControlName="cookingMode"
            classList="custom-input"
            bindValue="id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'RECIPE.COOKING_MODE' | translate"
            [readonly]="isCreate"
          >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              {{ item.name | translate }}
            </ng-template>
          </ng-select>
          <app-control-errors
            id="cookingMode"
            controlName="cookingMode"
          ></app-control-errors>
        </li>
        <li>
          <label class="main-label">{{ 'RECIPE.TYPE' | translate }}</label>
          <ng-select
            [items]="recipeTypeList"
            formControlName="typeId"
            classList="custom-input"
            bindValue="id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'RECIPE.TYPE' | translate"
          >
          </ng-select>
          <app-control-errors
            id="typeId"
            controlName="typeId"
          ></app-control-errors>
        </li>
        <li>
          <label class="main-label">{{ 'RECIPE.COUNTRY' | translate }}</label>
          <ng-select
            [items]="countryList"
            formControlName="country"
            classList="custom-input"
            bindValue="code"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'RECIPE.COUNTRY' | translate"
          >
          </ng-select>
          <app-control-errors
            id="country"
            controlName="country"
          ></app-control-errors>
        </li>
        <li>
          <label class="main-label">{{
            'RECIPE.PIECES_PEOPLE' | translate
          }}</label>
          <input
            type="text"
            class="custom-input"
            formControlName="pieces"
            [placeholder]="'RECIPE.PIECES_PEOPLE' | translate"
          />
          <app-control-errors id="pc" controlName="pieces"></app-control-errors>
        </li>
        <li>
          <label class="main-label">{{ 'RECIPE.TIME' | translate }}</label>
          <input
            type="text"
            class="custom-input"
            formControlName="time"
            [placeholder]="'RECIPE.TIME' | translate"
          />
          <app-control-errors id="time" controlName="time"></app-control-errors>
        </li>
        <li>
          <label class="main-label">{{ 'RECIPE.CALORIES' | translate }}</label>
          <input
            type="text"
            class="custom-input"
            formControlName="kcal"
            [placeholder]="'RECIPE.CALORIES' | translate"
          />
          <app-control-errors id="cal" controlName="kcal"></app-control-errors>
        </li>
        <li>
          <label class="main-label">{{ 'RECIPE.LABELS' | translate }}</label>
          <ng-select
            [items]="tags$ | async"
            bindLabel="name"
            [multiple]="true"
            [hideSelected]="true"
            [addTag]="true"
            [minTermLength]="2"
            typeToSearchText="{{ 'RECIPE.LABELS_PLACEHOLDER' | translate }}"
            [typeahead]="tagsInput$"
            formControlName="tags"
          >
          </ng-select>
        </li>
      </ul>
    </div>
    <div class="col-12 col-sm-5 col-md-4">
      <app-upload-image
        [isEditing]="isEditing"
        [imageUrl]="recipeImageUrl"
        otherId="recipe-main-image"
        (imageChangedBlob)="imageChanged($event)"
      ></app-upload-image>
    </div>
  </div>

  <div ngbAccordion #ngbAccordion>
    <div ngbAccordionItem *ngIf="!isEditing">
      <ng-template ngbPanelTitle>
        <h2>{{ 'RECIPE.INGREDIENTS' | translate }}</h2>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-ingredients
             [steps]="recipe.steps || []"
             [isEditing]="isEditing"
        ></app-ingredients>
      </ng-template>
    </div>

    <div ngbAccordionItem id="acc">
      <div ngbAccordionHeader>
        <button ngbAccordionButton>
          <h2>{{ 'RECIPE.ACCESSORIES' | translate }}</h2>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-accessories
              [isEditing]="isEditing"
              [controlValue]="recipeFormGroup.controls['accessories']"
            ></app-accessories>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem id="steps">
      <div ngbAccordionHeader>
        <button ngbAccordionButton>
          <h2>{{ 'RECIPE.STEPS' | translate }}</h2>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-steps
              [isEditing]="isEditing"
              [controlValue]="stepArrayControl"
            ></app-steps>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem id="prev">
      <div ngbAccordionHeader>
        <button ngbAccordionButton>
          <h2>{{ 'RECIPE.NABOO_DEVICE_PREVIEW' | translate }}</h2>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template [formGroup]="previewFormGroup">
            <button
              *ngIf="isEditing"
              id="update-button"
              class="button-primary"
              (click)="updatePreview()"
            >
              <i class="fa fa-pen"></i> {{ 'RECIPE.UPDATE_PREVIEW' | translate }}
            </button>
            <label class="custom-label"
              >{{ 'RECIPE.CONTEXT' | translate }}
              <textarea
                class="custom-input"
                rows="5"
                formControlName="context"
              ></textarea>
            </label>
            <br />
            <label class="custom-label"
              >{{ 'RECIPE.INGREDIENTS' | translate }}
              <textarea
                class="custom-input"
                rows="5"
                formControlName="previewIngredients"
              ></textarea>
            </label>
            <br />
            <label class="custom-label"
              >{{ 'RECIPE.STEPS' | translate }}
              <textarea
                class="custom-input"
                rows="5"
                formControlName="previewStep"
              ></textarea>
            </label>
            <br />
            <label class="custom-label"
              >{{ 'RECIPE.ACCESSORIES' | translate }}
              <textarea
                class="custom-input"
                rows="2"
                formControlName="previewAccessories"
              ></textarea>
            </label>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem id="cook">
      <div ngbAccordionHeader>
        <button ngbAccordionButton>
          <h2>{{ 'RECIPE.COOKING_STEP' | translate }}</h2>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <app-cooking-steps
              #cookingStepsComponentRef
              [cookingStep]="recipeFormGroup.controls['phases']"
              [preheat]="recipeFormGroup.controls['preheat']"
              [holding]="recipeFormGroup.controls['holding']"
              [isEditing]="isEditing"
              [cookingMode]="recipeFormGroup.get('cookingMode')?.value"
              [deviceModel]="recipeFormGroup.get('deviceModel')?.value"
              [tempUnit]="tempUnit"
              (resetPreheat)="resetPreheat()"
            ></app-cooking-steps>
          </ng-template>
        </div>
      </div>
    </div>
    
  </div>
</div>
