import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subject } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { DeviceDtoNBK, DeviceService, DisplayBoDtoNBK, DisplayService, UserKeycloakDtoNBK } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { EditDeviceModalComponent } from 'src/app/shared/modals/edit-device-modal/edit-device-modal.component';
import { ParameterDeviceComponent } from 'src/app/shared/modals/parameter-device/parameter-device.component';
import { DisplaySelectionModal } from '../../modals/display-selection-modal/display-selection-modal.component';
import { getCookingModeList, KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';

@Component({
  selector: 'app-device-action',
  templateUrl: './device-action.component.html',
  styleUrls: ['./device-action.component.scss']
})
export class DeviceActionComponent implements OnInit, OnDestroy {
  @Input() device: DeviceDtoNBK;
  @Input() kcUser: UserKeycloakDtoNBK;
  @Output() update: EventEmitter<void> = new EventEmitter();

  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private deviceService: DeviceService,
    private modalService: NgbModal,
    public authService: AuthService,
    private ts: TranslateService,
    private toastrService: ToastrService,
    private myDisplayService: DisplayService,
    private router: Router
  ) {  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  unpairDevice(): void {
    if (this.device.serial) {
      const serialNumber = this.device.serial;
      const modalRef = this.modalService.open(ConfirmModalComponent);

      modalRef.componentInstance.title = this.ts.instant(
        'DEVICES.DEVICE_CARD.MODALS.CONFIRM.TITLE'
      );
      modalRef.componentInstance.message = this.ts.instant(
        'DEVICES.DEVICE_CARD.MODALS.CONFIRM.MESSAGE',
        { deviceName: this.device.name }
      );

      modalRef.componentInstance.confirmAction
        .pipe(
          take(1),
          switchMap(() =>
            this.deviceService.removeCurrentUserFromDevice(serialNumber)
          ),
          finalize(() => {
            modalRef.close();
            this.update.emit();
          })
        )
        .subscribe();
    }
  }

  handleAssignDisplay() {
    forkJoin([
      this.myDisplayService
      .getAllDisplays(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, undefined, undefined, true, undefined,
        `mode eq '${DisplayBoDtoNBK.ModeEnum.MultiDisplay}' and deviceModel eq '${this.device.model}'`),
      this.myDisplayService.getDisplaysByDevice(this.device.serial!)
    ]).pipe(
      take(1),
      map((resp) => {
        if (resp[0].value && resp[0].value.length > 0) {
          this.handleDisplaySelection(resp[0].value, resp[1], this.device.id!);
        }
      })
    ).subscribe();
  }

  private handleDisplaySelection( displays: DisplayBoDtoNBK[], deviceCurrentDisplayIds: number[], deviceId: number ) {
    const modalRef = this.modalService.open(DisplaySelectionModal, {
      backdrop: 'static',
      modalDialogClass: 'modal-md'
    });
    modalRef.componentInstance.displays = displays;
    modalRef.componentInstance.currentDisplayIds = deviceCurrentDisplayIds;
    modalRef.componentInstance.cookingMode = getCookingModeList(this.device.model!);
    modalRef.componentInstance.displaySelected.subscribe((display: DisplayBoDtoNBK) => {
      const alreadyAssociated = display.devices?.map( d => d.id!) || [];
      this.myDisplayService
      .assignDisplayToDevices(display.id!, { ids: [deviceId, ...alreadyAssociated] })
      .pipe(take(1))
      .subscribe(() => {
        this.toastrService.success(
          this.ts.instant('MY_DISPLAY.DISPLAY_CHANGED'),
          this.ts.instant('GLOBAL.SUCCESS')
        );
      });
    });
  }

  changeName(): void {
    if (this.device.id) {
      const modalRef = this.modalService.open(EditDeviceModalComponent);
      modalRef.componentInstance.deviceName = this.device.name || '';

      modalRef.componentInstance.updateName
        .pipe(
          take(1),
          switchMap((newName: string) =>
            this.deviceService.renameDevice(this.device.id!, newName)
          ),
          finalize(() => {
            modalRef.close();
            this.update.emit();
          })
        )
        .subscribe();
    }
  }

  showTelemetry(): void {
    this.router.navigate(['/devices', this.device.serial, 'telemetry']);
  }

  showStatistics(): void {
    this.router.navigate(['/devices', this.device.serial, 'statistics']);
  }

  showParameter(): void {
    const modalRef = this.modalService.open(ParameterDeviceComponent, {
      size: 'xl'
    });
    modalRef.componentInstance.deviceId = this.device.id;
  }

}
