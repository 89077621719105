import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DeviceBoDtoNBK, DeviceDtoNBK, UserKeycloakDtoNBK } from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import {
  InfoDeviceEnum,
  showDeviceInfo
} from 'src/app/core/device.utils';
import { DictionaryService } from 'src/app/core/dictionary.service';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss']
})
export class DeviceCardComponent implements OnInit, OnDestroy {
  @Input() device: DeviceBoDtoNBK;
  @Input() kcUser: UserKeycloakDtoNBK;
  @Output() update: EventEmitter<void> = new EventEmitter();

  unsubscribe$: Subject<void> = new Subject();

  lang: string;
  InfoDeviceEnum = InfoDeviceEnum;
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(
    private dictionary: DictionaryService,
    public authService: AuthService,
    private ts: TranslateService,
  ) {
    this.dictionary.lang$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((lang) => {
          this.lang = lang;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkIfViewInfo(key: InfoDeviceEnum) {
    return showDeviceInfo(key);
  }

  loadDataSource() {
    this.update.emit();
  }
  
}
