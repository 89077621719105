import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DatatableComponent as NgxDatatableComponent,
  SelectionType,
  TableColumn
} from '@swimlane/ngx-datatable';
import {
  ODataFiltrableDataSource
} from 'filtrable-data-source';
import { SelectedItemsHandler } from 'src/app/core/selected-items-handler';

@Component({
  selector: 'app-datatable-paginated',
  templateUrl: './datatable-paginated.component.html',
  styleUrls: ['./datatable-paginated.component.scss']
})
export class DatatablePaginatedComponent implements OnInit {
  @Input() groupRowsBy?: string;
  @Input() scrollable = true;
  @Input() hidePagination? = false;
  @Input() disabledLines?: boolean;
  @Input() dataSource: ODataFiltrableDataSource<any>;
  _selectionHandler?: SelectedItemsHandler<any>;
  @Input() set selectionHandler(val: SelectedItemsHandler<any>) {
    this._selectionHandler = val;
    this.parseColumns();
  }
  @Input()
  set columns(val: TableColumn[]) {
    this._columns = val;
  }
  @Input() selected: any[] = [];
  @Input() selectionType?: SelectionType = undefined;
  @Output() elSelected = new EventEmitter<any>();

  @ViewChild('checkboxTpl', { static: true }) checkboxTpl: TemplateRef<any>;
  @ViewChild(NgxDatatableComponent, { static: true })
  ngxDatatable: NgxDatatableComponent;
  @Input() messages? = { emptyMessage: this.ts.instant('GLOBAL.NO_DATA') };
  _columns: TableColumn[] = [];

  @Input() className?: string;

  constructor(private ts: TranslateService, private route: ActivatedRoute) {}

  async ngOnInit() {
    if (this._selectionHandler) {
      this._selectionHandler.itemsChanged$.subscribe(() => {
        // force ngx-datatable to execute change detection to reflect selection state
        this.selected = [...this.selected];
      });
    }
  }

  getRowClass = (row: any) => {
    if (this._selectionHandler) {
      return {
        active: this._selectionHandler.isSelected(row)
      };
    }
    return {};
  };

  private parseColumns() {
    if (this._selectionHandler) {
      this._columns = [
        {
          width: 40,
          sortable: false,
          canAutoResize: false,
          draggable: false,
          resizeable: false,
          cellTemplate: this.checkboxTpl
        },
        ...this._columns
      ];
    }
  }
}
