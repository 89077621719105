import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AllergenBoDtoNBK,
  AllergenService,
  IngredientBoDtoNBK,
  IngredientService,
  IngredientTypeBoDtoNBK,
  IngredientTypeService
} from 'src/app/api/nbk';
import { ResultSetAllergenDtoNBK } from 'src/app/api/nbk/model/resultSetAllergenDto';
import { KEY_LANGUAGE_STORAGE, umList } from 'src/app/core/utils';
import { IngredientBoDtoNBKComplete } from '../recipe-detail/ingredients/ingredients.service';

@Component({
  selector: 'app-create-ingredient-modal',
  templateUrl: './create-ingredient-modal.component.html',
  styleUrls: ['./create-ingredient-modal.component.scss']
})
export class CreateIngredientModalComponent implements OnInit {
  ingredient?: IngredientBoDtoNBKComplete;
  ingredientFormGroup: UntypedFormGroup;

  typeList: IngredientTypeBoDtoNBK[] = [];
  umList = umList;
  allergensList: AllergenBoDtoNBK[] = [];

  @Output() ingredientCreated: EventEmitter<IngredientBoDtoNBK> = new EventEmitter();
  @Output()ingredientEdited = new EventEmitter<UntypedFormGroup>();

  constructor(
    public activeModal: NgbActiveModal,
    private allergensService: AllergenService,
    private ingredientService: IngredientService,
    private ingredientTypeService: IngredientTypeService
  ) {}

  ngOnInit(): void {
    this.allergensService
      .getAllAllergens(localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
      .subscribe((res: ResultSetAllergenDtoNBK) => {
        this.allergensList = res.value || [];
      });

    this.ingredientTypeService
      .getAllIngredientTypes(localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
      .subscribe((res) => {
        this.typeList = res.value || [];
      });

    this.ingredientFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(),
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      type: new UntypedFormControl(null),
      um: new UntypedFormControl(this.umList[0]),
      allergens: new UntypedFormControl([]),
      density: new UntypedFormControl(0),
      calories: new UntypedFormControl(0),
      creationLanguage: new UntypedFormControl(
        localStorage.getItem(KEY_LANGUAGE_STORAGE)!
      )
    });

    if (this.ingredient) {
      this.ingredientFormGroup.patchValue(this.ingredient);
    }
  }

  createNewIngredient() {
    if (this.ingredientFormGroup.valid) {
      const ingredient = { ...this.ingredientFormGroup.value, ics: false };
      this.ingredientService
        .insertIngredient(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, ingredient)
        .subscribe((res: IngredientBoDtoNBK) => {
          this.ingredientCreated.emit(res);
          this.activeModal.close();
        });
    }
  }
  editIngredient() {
    if (this.ingredientFormGroup.valid) {
      const ingredient = { ...this.ingredientFormGroup.value };
      this.ingredientService
        .updateIngredient(localStorage.getItem(KEY_LANGUAGE_STORAGE)!, ingredient)
        .subscribe((res: IngredientBoDtoNBK) => {
          this.ingredientEdited.emit(this.ingredientFormGroup);
          this.activeModal.close();
        });
    }
  }
}
