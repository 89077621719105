import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {
  transform(
    value: string | number,
    pattern: string = 'fullDate'
  ): string | null {
    const datePipe: DatePipe = new DatePipe(
      localStorage.getItem(KEY_LANGUAGE_STORAGE) || 'en'
    );
    return datePipe.transform(value, pattern);
  }
}
