import { Injectable } from '@angular/core';
import {
  and,
  contains,
  equal,
  greaterThanOrEqual,
  lessThanOrEqual,
  not,
  ODataFiltrableDataSource,
  valueIn
} from 'filtrable-data-source';
import { RecipeBoDtoNBK } from '../../api/nbk';

interface filterAction {
  [key: string]: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class RecipeFiltersService {
  applyDefaultFilters: boolean = false;
  filterFormValue: any;

  constructor() { }

  setFilterForm(form: any) {
    this.filterFormValue = form;
  }

  setFilterNamePrefix ( prefix: string | undefined, filternName: string ) {
    return prefix !== undefined ? prefix + filternName : filternName
  }

  setCustomFilter(
    filterNamePrefix: string | undefined,
    recipesList: ODataFiltrableDataSource<RecipeBoDtoNBK>,
    isLainoxRecipe: boolean
  ) {
    const setFilter: filterAction = {
      name: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'name/value'), 
          contains(this.filterFormValue.name));
      },
      timeFrom: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'time'),
          this.filterFormValue.timeTo
            ? and(
              greaterThanOrEqual(this.filterFormValue.timeFrom),
              lessThanOrEqual(this.filterFormValue.timeTo)
            )
            : greaterThanOrEqual(this.filterFormValue.timeFrom)
        );
      },
      timeTo: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'time'),
          this.filterFormValue.timeFrom
            ? and(
              greaterThanOrEqual(this.filterFormValue.timeFrom),
              lessThanOrEqual(this.filterFormValue.timeTo)
            )
            : lessThanOrEqual(this.filterFormValue.timeTo)
        );
      },
      caloriesFrom: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'kcal'),
          this.filterFormValue.caloriesTo
            ? and(
              greaterThanOrEqual(this.filterFormValue.caloriesFrom),
              lessThanOrEqual(this.filterFormValue.caloriesTo)
            )
            : greaterThanOrEqual(this.filterFormValue.caloriesFrom)
        );
      },
      caloriesTo: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'kcal'),
          this.filterFormValue.caloriesFrom
            ? and(
              greaterThanOrEqual(this.filterFormValue.caloriesFrom),
              lessThanOrEqual(this.filterFormValue.caloriesTo)
            )
            : lessThanOrEqual(this.filterFormValue.caloriesTo)
        );
      },
      onlyPersonal: () => {
        if (!isLainoxRecipe) {
          recipesList.setFilter(
            this.setFilterNamePrefix(filterNamePrefix,'ics'), 
            equal(false));
        }
      },
      type: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'type/id'), 
          equal(this.filterFormValue.type));
      },
      country: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'nation/code'),
          equal(this.filterFormValue.country)
        );
      },
      tags: () => {
        recipesList.setFilter(
          this.setFilterNamePrefix(filterNamePrefix,'tags/id'),
          valueIn(this.filterFormValue.tags.map((t: any) => t.id))
        );
      },
      noIngredients: () => {
        recipesList.setCustomFilter(
          `length(${this.setFilterNamePrefix(filterNamePrefix, "steps")}) eq 0`
        );
      },
      noCookingStep: () => {
        recipesList.setCustomFilter(
          `length(${this.setFilterNamePrefix(filterNamePrefix, "phases")}) eq 0`
        );
      }
    };

    this.resetFilter(filterNamePrefix, recipesList, isLainoxRecipe!);

    for (const field in this.filterFormValue) {
      if (this.filterFormValue[field]) {
        setFilter[field]();
      }
    }
  }

  resetFilter(
    filterNamePrefix: string | undefined,
    recipesList: ODataFiltrableDataSource<RecipeBoDtoNBK>,
    isLainoxRecipe: boolean
  ) {
    recipesList.removeFilter(this.setFilterNamePrefix(filterNamePrefix,'name/value'));
    recipesList.removeFilter(this.setFilterNamePrefix(filterNamePrefix,'time'));
    recipesList.removeFilter(this.setFilterNamePrefix(filterNamePrefix,'kcal'));
    recipesList.removeFilter(this.setFilterNamePrefix(filterNamePrefix,'type/id'));
    recipesList.removeFilter(this.setFilterNamePrefix(filterNamePrefix,'nation/code'));
    recipesList.removeFilter(this.setFilterNamePrefix(filterNamePrefix,'tags/id'));
    if (!isLainoxRecipe) {
      recipesList.removeFilter(this.setFilterNamePrefix(filterNamePrefix,'ics'));
    }
    recipesList.removeCustomFilter();
  }
}
