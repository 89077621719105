import { Options } from '@angular-slider/ngx-slider';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() color: string;
  @Input() control: UntypedFormControl;
  @Input() showValue?: boolean = true;
  @Input() floor?: number = 0;
  @Input() step?: number = 0;
  @Input() ceil: number;
  @Input() time?: boolean = false;
  @Input() double?: boolean = false;
  @Input() percentage?: boolean = false;
  @Input() minLimit?: number;
  @Input() maxLimit?: number;
  @Input() tempUnit?: 'C' | 'F' = 'C';
  @Input() temperature?: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showButton: boolean = false;
  @Input() firstStep: number = 0;

  options: Options;
  constructor() {}

  ngOnChanges(): void {
    this.options = {
      step: this.step,
      floor: this.floor,
      ceil: this.ceil,
      hideLimitLabels: true,
      handleDimension: 0,
      hidePointerLabels: true,
      disabled: this.disabled,
      minLimit: this.minLimit,
      maxLimit: this.maxLimit
    };
  }

  ngOnInit(): void {}

  increase() {
    const newValue =
      this.firstStep && this.control.value < this.firstStep
        ? this.control.value + this.firstStep!
        : this.control.value + this.options.step!;
    this.control.setValue(newValue);
  }

  decrease() {
    const newValue =
      this.firstStep && this.control.value <= this.firstStep
        ? 0
        : this.control.value - this.options.step!;
    this.control.setValue(newValue);
  }
}
