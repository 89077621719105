import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  AccessoryBoDtoNBK,
  AccessoryService,
  ResultSetAccessoryDtoNBK
} from 'src/app/api/nbk';
import { AuthService } from 'src/app/core/auth.service';
import { KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';
import { CreateAccessoryModalComponent } from './create-accessory-modal/create-accessory-modal.component';
import { isSuperuserOrChefLainox } from 'src/app/core/permission.utils';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent implements OnInit, OnDestroy {
  @Input() controlValue: AbstractControl;
  @Input() isEditing: boolean;

  updateAccessories$ = new Subject<void>();
  unsubscribe$: Subject<void> = new Subject();

  accessoryControl = new UntypedFormControl('', Validators.required);
  accessoryList: AccessoryBoDtoNBK[] = [];

  constructor(
    private modalService: NgbModal,
    private accessoryService: AccessoryService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.controlValue;
    this.updateAccessories$
      .pipe(
        takeUntil(this.unsubscribe$),
        startWith(0),
        switchMap(() =>
          this.accessoryService
            .getAllAccessories(localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
            .pipe(catchError(() => of({ value: [] })))
        ),
        tap((res: ResultSetAccessoryDtoNBK) => {
          this.accessoryList = res.value || [];
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createNewAccessory() {
    const modalRef = this.modalService.open(CreateAccessoryModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.isCreate = true;
    modalRef.componentInstance.accessoryCreated.subscribe((v: any) => {
      this.updateAccessories$.next();
    });
  }

  addAccessory(accessory?: any) {
    if (this.accessoryControl.valid) {
      accessory = this.accessoryControl.value;
      this.accessoryControl.setValue('');
      (<UntypedFormArray>this.controlValue).push(new UntypedFormControl(accessory));
    }
  }

  removeAccessory(i: number) {
    (<UntypedFormArray>this.controlValue).removeAt(i);
  }

  canHandle() {
    return isSuperuserOrChefLainox(this.authService);
  }

}
