import { IngredientBoDtoNBK, RecipeBoDtoNBK, RecipeDtoNBK } from "../api/nbk";
import { AuthService } from "./auth.service";

export type IcsEntity = IngredientBoDtoNBK | RecipeBoDtoNBK | RecipeDtoNBK

export const canHandleIcsItem =  (item: IcsEntity, authService: AuthService) => {
    return item.ics && ( authService.permissions.CHEF_LAINOX || authService.permissions.SUPERUSER ) 
        || !item.ics && item.user === authService.userId;
}

export const canMarkAsIcs = (item: IcsEntity, authService: AuthService) => {
    return item.ics === false &&
      (authService.permissions.CHEF_LAINOX || authService.permissions.SUPERUSER);
}

export const isSuperuserOrChefLainox = (authService: AuthService) => {
    return authService.permissions.CHEF_LAINOX || authService.permissions.SUPERUSER;
}
