import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const prefixTranslationICS = 'RECIPE.ICS';
export const translationICS = [_('RECIPE.ICS.COMBI'), _('RECIPE.ICS.HSO')];

export const converterTempUnit = (value: number, unit: 'F' | 'C') => {
  const fromCelsiusToFahrenheit = (c: number) => {
    return Math.round((((c - 32) * 5) / 9) * 100) / 100;
  };
  const fromFahrenheitToCelsius = (f: number) => {
    return Math.round(((f * 9) / 5 + 32) * 100) / 100;
  };

  return unit === 'F'
    ? fromFahrenheitToCelsius(value)
    : fromCelsiusToFahrenheit(value);
};
