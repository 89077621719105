import { RecipeBoDtoNBK } from '../api/nbk';

export const PH_COMBI_CONFIG = {
  MAX_VALUE_PREHEAT: 300,
  MIN_VALUE_PREHEAT: 36,
  PERCENTAGE_MIN_VALUE_PREHEAT: 0.2
};
export const PH_HSO_CONFIG = {
  MAX_VALUE_PREHEAT: 300,
  MIN_VALUE_PREHEAT: 100,
  DEFAULT_VALUE: 250,
  PERCENTAGE_MIN_VALUE_PREHEAT: 1
};
export const MAINTENANCE_CONFIG = {
  MAX_VALUE: 80,
  MIN_VALUE: 30
};

export const MAX_VALUE_TEMPERATURE = 300;
export const MIN_VALUE_TEMPERATURE = 30;

export const MICROWAVE_SETUP = {};
export const TIMER_SETUP = {};
export const FAN_SETUP = {};

export type TTemperatureUnit = 'C' | 'F';

export interface IConfigSliderCookingStep {
  ceil?: number;
  floor?: number;
  step?: number;
  time?: boolean;
  firstStep?: number;
  minLimit?: number;
  maxLimit?: number;
}

export const convertToFahrenheit = (celsius: number): number => {
  return parseInt(((celsius * 9) / 5 + 32).toFixed(2));
};

export const convertToCelsius = (farhenheit: number): number => {
  return parseInt((((farhenheit - 32) * 5) / 9).toFixed(2));
};

export const convertRecipeToFahrenheit = (recipe: RecipeBoDtoNBK) => {
  recipe.preheat && recipe.preheat.temperature
    ? (recipe.preheat.temperature = convertToFahrenheit(
        recipe.preheat.temperature
      ))
    : null;

  recipe.holding && recipe.holding.temperature
    ? (recipe.holding.temperature = convertToFahrenheit(
        recipe.holding.temperature
      ))
    : null;

  recipe.phases?.forEach((phase) => {
    phase && phase.temperature
      ? (phase.temperature = convertToFahrenheit(phase.temperature))
      : null;
  });
};

export const convertRecipeToCelsius = (recipe: RecipeBoDtoNBK) => {
  recipe.preheat && recipe.preheat.temperature
    ? (recipe.preheat.temperature = convertToCelsius(
        recipe.preheat.temperature
      ))
    : null;

  recipe.holding && recipe.holding.temperature
    ? (recipe.holding.temperature = convertToCelsius(
        recipe.holding.temperature
      ))
    : null;

  recipe.phases?.forEach((phase) => {
    phase && phase.temperature
      ? (phase.temperature = convertToCelsius(phase.temperature))
      : null;
  });
};

export const preheatConfiguration = (
  cookingMode: RecipeBoDtoNBK.CookingModeEnum,
  tempUnit: TTemperatureUnit
) => {
  return cookingMode === 'COMBI'
    ? {
        ceil:
          tempUnit === 'C'
            ? PH_COMBI_CONFIG.MAX_VALUE_PREHEAT
            : convertToFahrenheit(PH_COMBI_CONFIG.MAX_VALUE_PREHEAT),
        floor:
          tempUnit === 'C'
            ? PH_COMBI_CONFIG.MIN_VALUE_PREHEAT
            : convertToFahrenheit(PH_COMBI_CONFIG.MIN_VALUE_PREHEAT)
      }
    : {
        ceil:
          tempUnit === 'C'
            ? PH_HSO_CONFIG.MAX_VALUE_PREHEAT
            : convertToFahrenheit(PH_HSO_CONFIG.MAX_VALUE_PREHEAT),
        floor:
          tempUnit === 'C'
            ? PH_HSO_CONFIG.MIN_VALUE_PREHEAT
            : convertToFahrenheit(PH_HSO_CONFIG.MIN_VALUE_PREHEAT)
      };
};

export const temperatureConfiguration = (tempUnit: TTemperatureUnit) => {
  return {
    ceil:
      tempUnit === 'C'
        ? MAX_VALUE_TEMPERATURE
        : convertToFahrenheit(MAX_VALUE_TEMPERATURE),
    floor:
      tempUnit === 'C'
        ? MIN_VALUE_TEMPERATURE
        : convertToFahrenheit(MIN_VALUE_TEMPERATURE),
    minLimit:
      tempUnit === 'C'
        ? MIN_VALUE_TEMPERATURE
        : convertToFahrenheit(MIN_VALUE_TEMPERATURE)
  };
};

export const maintenanceConfiguration = (tempUnit: TTemperatureUnit) => {
  return {
    ceil:
      tempUnit === 'C'
        ? MAINTENANCE_CONFIG.MAX_VALUE
        : convertToFahrenheit(MAINTENANCE_CONFIG.MAX_VALUE),
    floor:
      tempUnit === 'C'
        ? MAINTENANCE_CONFIG.MIN_VALUE
        : convertToFahrenheit(MAINTENANCE_CONFIG.MIN_VALUE),
    minLimit:
      tempUnit === 'C'
        ? MAINTENANCE_CONFIG.MIN_VALUE
        : convertToFahrenheit(MAINTENANCE_CONFIG.MIN_VALUE)
  };
};
