import { Injectable } from '@angular/core';
import { PhaseBoDtoNBK, PreheatDtoNBK, RecipeDtoNBK } from 'src/app/api/nbk';
import {
  PH_COMBI_CONFIG,
  PH_HSO_CONFIG,
  preheatConfiguration,
  temperatureConfiguration,
  TTemperatureUnit
} from 'src/app/core/cooking-step.utils';

@Injectable({
  providedIn: 'root'
})
export class RecipeDetailService {
  private preheatInitialTemperature: number = 0;
  private preheatMinimumValue: number = 0;
  public preheatDisabled: boolean = true;

  constructor() {}

  updatePreheatTemperature(
    cookingMode: any,
    temp: number,
    tempUnit: TTemperatureUnit
  ) {
    if (cookingMode === 'COMBI') {
      const temperatureWithPercentage =
        temp + temp * PH_COMBI_CONFIG.PERCENTAGE_MIN_VALUE_PREHEAT;
      this.preheatInitialTemperature =
        temperatureWithPercentage >
        preheatConfiguration(cookingMode, tempUnit).ceil
          ? preheatConfiguration(cookingMode, tempUnit).ceil
          : temperatureWithPercentage;
      this.preheatMinimumValue = temp;
    }
  }

  initPreheatTemperature(
    cookingMode: RecipeDtoNBK.CookingModeEnum,
    isFirst: boolean,
    phase: PhaseBoDtoNBK | null,
    preheat: PreheatDtoNBK,
    tempUnit: TTemperatureUnit
  ) {
    if (cookingMode === 'COMBI') {
      if (isFirst) {
        if (!(preheat && preheat.temperature)) {
          const firstTemperature = phase
            ? phase.temperature && phase.temperature > 0
              ? phase.temperature
              : temperatureConfiguration(tempUnit).floor
            : temperatureConfiguration(tempUnit).floor;
          this.preheatInitialTemperature =
            firstTemperature +
            firstTemperature * PH_COMBI_CONFIG.PERCENTAGE_MIN_VALUE_PREHEAT;
          this.preheatMinimumValue = firstTemperature;
        } else {
          this.preheatMinimumValue = phase
            ? phase.temperature && phase.temperature > 0
              ? phase.temperature
              : temperatureConfiguration(tempUnit).floor
            : temperatureConfiguration(tempUnit).floor;
        }
      } else {
        this.preheatInitialTemperature = preheat.temperature! | 0;
      }
    } else if (cookingMode === 'HSO') {
      const preheatInitial =
        preheat && preheat.temperature
          ? preheat.temperature
          : PH_HSO_CONFIG.DEFAULT_VALUE;
      this.preheatInitialTemperature = preheatInitial;
      this.preheatMinimumValue = PH_HSO_CONFIG.MIN_VALUE_PREHEAT;
    }
  }

  disabledPreheat(
    cookingMode: RecipeDtoNBK.CookingModeEnum,
    tempUnit: TTemperatureUnit
  ) {
    this.preheatDisabled = false;
    this.preheatInitialTemperature = preheatConfiguration(
      cookingMode,
      tempUnit
    ).floor;
    this.preheatMinimumValue = preheatConfiguration(
      cookingMode,
      tempUnit
    ).floor;
  }

  enablePreheat() {
    this.preheatDisabled = true;
  }

  getPreheatInitialTemperature() {
    return Math.round(this.preheatInitialTemperature);
  }

  getPreheatMinimumValue() {
    return this.preheatMinimumValue;
  }
}
