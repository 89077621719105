import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'temperatureUnit'
})
export class TemperatureUnitPipe implements PipeTransform {
  transform(value: number, ...args: string[]): unknown {
    return value ? this.tempConverter(value, args[0]) : undefined;
  }

  tempConverter(value: number, unit: string): string {
    switch (unit) {
      case 'F':
        return `${value.toString()} \u00B0${unit}`;
      case 'C':
        return `${value.toString()} \u00B0${unit}`;

      default:
        return value.toString();
    }
  }
}
