<div *ngIf="preheatValue">
  <p>
    {{ 'COOKINGSTEP.PREHEAT' | translate }}:
    {{ preheatValue | temperatureUnit: tempUnit }}
  </p>
</div>
<ngx-datatable
  class="material fixed-tooltip"
  [columns]="columns"
  [rows]="cookingSteps.currentItems"
  [limit]="cookingSteps.limit"
  [offset]="cookingSteps.page"
  [count]="cookingSteps.totalItems || 0"
  [externalPaging]="true"
  [externalSorting]="true"
  [scrollbarH]="true"
  columnMode="force"
  (page)="cookingSteps.onTablePage($event)"
  (sort)="cookingSteps.onTableSort($event)"
  [selectAllRowsOnPage]="false"
>
</ngx-datatable>
<div *ngIf="holdingType !== 'Off'">
  <p>
    {{ 'COOKINGSTEP.HOLDING' | translate }}: {{ holdingType }} /
    {{ 'COOKINGSTEP.SET' | translate }}:
    {{ holdingValue | temperatureUnit: tempUnit }}
  </p>
</div>
<br />
<button
  *ngIf="isEditing"
  id="create-button"
  class="button-primary mx-auto d-block"
  (click)="createNewStep()"
>
  <i class="fa fa-plus"></i> {{ 'RECIPE.ADD_COOOKING_CYCLES' | translate }}
</button>

<ng-template #editTpl let-row="row" let-rowIndex="rowIndex">
  <button
    (click)="editCookingStep(row, rowIndex)"
    class="button-primary"
    *ngIf="isEditing"
  >
    <i class="fa fa-pen"></i>
  </button>
  <button
    (click)="removeCookingStep(rowIndex)"
    class="button-danger mx-1"
    *ngIf="isEditing"
  >
    <i class="fa fa-times"></i>
  </button>
</ng-template>

<ng-template #idTpl let-row="row" let-rowIndex="rowIndex" let-value="value">
  {{rowIndex + 1}}
</ng-template>

<ng-template #cookingTypeTpl let-row="row" let-value="value">
  {{ value }}
</ng-template>

<ng-template #temperatureTpl let-row="row">
  {{ row.temperature | temperatureUnit: tempUnit }}
</ng-template>

<ng-template #microwaveTpl let-row="row">
  <span *ngIf="row.magnetron; else noValue">{{ row.magnetron }}%</span>
</ng-template>

<ng-template #fanSpeedTpl let-row="row">
  <span *ngIf="row.fanSpeed; else noValue">{{ row.fanSpeed }}%</span>
</ng-template>

<ng-template #timerTpl let-row="row">
  {{ row.time | timeRange }}
</ng-template>

<ng-template #actionTpl let-row="row">
  <span class="action-tpl" #el [tooltip]="setActionValue(row.actions)" placement="bottom" [isDisabled]="disableTooltip(el)">
    {{ setActionValue(row.actions) }}
  </span>
</ng-template>

<ng-template #noValue>{{ 'GLOBAL.NULL_VALUE' | translate }}</ng-template>
