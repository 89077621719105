import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { Subject } from 'rxjs';
import { PhaseActionBoDtoNBK, PhaseBoDtoNBK, RecipeBoDtoNBK } from 'src/app/api/nbk';
import { TTemperatureUnit } from 'src/app/core/cooking-step.utils';
import { EditCookingStepsModalComponent } from './edit-cooking-steps-modal/edit-cooking-steps-modal.component';
import { disableTooltip } from 'src/app/core/utils';
@Component({
  selector: 'app-cooking-steps',
  templateUrl: './cooking-steps.component.html',
  styleUrls: ['./cooking-steps.component.scss']
})
export class CookingStepsComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() isEditing: boolean;
  @Input() cookingStep: AbstractControl;
  @Input() preheat?: AbstractControl;
  @Input() holding?: AbstractControl;
  @Input() cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  @Input() deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  @Input() tempUnit: TTemperatureUnit = 'C';

  @Output() resetPreheat: EventEmitter<void> = new EventEmitter();

  cookingSteps = new ClientFiltrableDataSource<PhaseBoDtoNBK>();
  columns: TableColumn[] = [];

  @ViewChild('actionTpl', { static: true }) 
  actionTpl: ElementRef<HTMLElement>;

  @ViewChild('editTpl', { static: true }) 
  editTpl: ElementRef<HTMLElement>;

  @ViewChild('idTpl', { static: true }) 
  idTpl: ElementRef<HTMLElement>;

  @ViewChild('cookingTypeTpl', { static: true })
  cookingTypeTpl: ElementRef<HTMLElement>;

  @ViewChild('temperatureTpl', { static: true })
  temperatureTpl: ElementRef<HTMLElement>;

  @ViewChild('timerTpl', { static: true })
  timerTpl: ElementRef<HTMLElement>;

  @ViewChild('microwaveTpl', { static: true })
  microwaveTpl: ElementRef<HTMLElement>;

  @ViewChild('fanSpeedTpl', { static: true })
  fanSpeedTpl: ElementRef<HTMLElement>;

  private unsubscribe$: Subject<void> = new Subject();

  get holdingType() {
    return (this.holding as UntypedFormGroup).controls['type'].value;
  }
  get holdingValue() {
    return (this.holding as UntypedFormGroup).controls['temperature'].value;
  }

  get preheatType() {
    return (this.preheat as UntypedFormGroup).controls['type'].value;
  }
  get preheatValue() {
    return (this.preheat as UntypedFormGroup).controls['temperature'].value;
  }

  constructor(private ts: TranslateService, private modalService: NgbModal, private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'id',
        name: this.ts.instant('COOKINGSTEP.CYCLES'),
        cellTemplate: this.idTpl
      },
      {
        prop: 'cookingType',
        name: this.ts.instant('COOKINGSTEP.MODE'),
        cellTemplate: this.cookingTypeTpl
      },
      {
        prop: 'temperature',
        name: this.ts.instant('COOKINGSTEP.TEMPERATURE'),
        cellTemplate: this.temperatureTpl
      },
      {
        prop: 'magnetron',
        name: this.ts.instant('COOKINGSTEP.MICROWAVE'),
        cellTemplate: this.microwaveTpl
      },
      {
        prop: 'time',
        name: this.ts.instant('COOKINGSTEP.TIMER'),
        cellTemplate: this.timerTpl
      },
      {
        prop: 'fanSpeed',
        name: this.ts.instant('COOKINGSTEP.VENTILATION'),
        cellTemplate: this.fanSpeedTpl
      },
      {
        prop: '',
        name: this.ts.instant('COOKINGSTEP.ACTION'),
        cellTemplate: this.actionTpl
      },
      {
        prop: '',
        name: '',
        cellTemplate: this.editTpl
      }
    ];

    this.prepareSteps();
  }

  prepareSteps() {
    this.cookingSteps.setItems(this.cookingStep.value || []);
  }

  editCookingStep(phase: PhaseBoDtoNBK, phaseIndex: number) {
    const modalRef = this.modalService.open(EditCookingStepsModalComponent, {
      modalDialogClass: 'modal-xl'
    });

    modalRef.componentInstance.phase = phase;
    modalRef.componentInstance.firstPhase = phaseIndex === 0;
    modalRef.componentInstance.tempUnit = this.tempUnit;
    modalRef.componentInstance.cookingMode = this.cookingMode;
    modalRef.componentInstance.deviceModel = this.deviceModel;
    modalRef.componentInstance.selected = Number(phaseIndex);
    modalRef.componentInstance.preheat = this.preheat?.value;
    modalRef.componentInstance.cookingSteps = this.cookingStep.value;
    modalRef.componentInstance.holding = this.holding?.value;
    modalRef.componentInstance.cookingStepsEdited.subscribe(
      (result: {
        index: number;
        formData: UntypedFormGroup;
        holdingData: UntypedFormGroup;
        preheatData: UntypedFormGroup;
      }) => {
        modalRef.close();
        (this.cookingStep as UntypedFormArray).setControl(
          result.index,
          result.formData
        );
        this.prepareSteps();
        this.holding?.setValue(result.holdingData.value);
        this.preheat?.setValue(result.preheatData.value);
      }
    );
  }

  createNewStep() {
    const modalRef = this.modalService.open(EditCookingStepsModalComponent, {
      modalDialogClass: 'modal-xl'
    });

    modalRef.componentInstance.firstPhase =
      this.cookingSteps.currentItems.length === 0;
    modalRef.componentInstance.preheat = this.preheat;
    modalRef.componentInstance.tempUnit = this.tempUnit;
    modalRef.componentInstance.cookingMode = this.cookingMode;
    modalRef.componentInstance.deviceModel = this.deviceModel;
    modalRef.componentInstance.cookingSteps = this.cookingStep.value;
    modalRef.componentInstance.preheat = this.preheat?.value;
    modalRef.componentInstance.holding = this.holding?.value;
    modalRef.componentInstance.cookingStepCreated.subscribe(
      (v: {
        formData: UntypedFormGroup;
        holdingData: UntypedFormGroup;
        preheatData: UntypedFormGroup;
      }) => {
        modalRef.close();
        (this.cookingStep as UntypedFormArray).push(v.formData);
        this.prepareSteps();

        this.holding?.setValue(v.holdingData.value);
        this.preheat?.setValue(v.preheatData.value);
      }
    );
  }

  removeCookingStep(phaseIndex: string) {
    const index = parseInt(phaseIndex);
    (this.cookingStep as UntypedFormArray).removeAt(index);
    if (this.cookingStep.value.length === 0) {
      this.resetPreheat.emit();
    }
    this.prepareSteps();
  }

  clearCookingStep() {
    this.cookingSteps.setItems([]);
    this.preheat?.reset();
    this.holding?.reset();
    this.resetPreheat.emit();
  }

  setActionValue(actions: PhaseActionBoDtoNBK[]) : string {
    if ( actions ) {
      if ( actions.length == 1 && actions[0].type == 'CUSTOM') {
        return actions[0].customAction!;
      } else {
        return actions.map( a => this.ts.instant(`RECIPE.PHASE.ACTION.${a.type}`) ).join(', ');
      }
    }
    return '';
  }

  disableTooltip(el: HTMLSpanElement) {
    return disableTooltip(el);
  }

}
