import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  IngredientBoDtoNBK,
  IngredientService,
  IngredientTypeBoDtoNBK,
  IngredientTypeService,
  ResultSetIngredientDtoNBK,
  ResultSetIngredientTypeDtoNBK,
  StepIngredientDtoNBK
} from 'src/app/api/nbk';
import { KEY_LANGUAGE_STORAGE } from 'src/app/core/utils';

export type IngredientBoDtoNBKComplete = IngredientBoDtoNBK & {
  quantity: number;
  um: string;
};

@Injectable({
  providedIn: 'root'
})
export class IngredientsService {
  ingredients$ = new BehaviorSubject<IngredientBoDtoNBK[]>([]);
  ingredients: IngredientBoDtoNBK[] = [];

  typeIngredients$ = new BehaviorSubject<IngredientTypeBoDtoNBK[]>([]);
  typeIngredients: IngredientTypeBoDtoNBK[] = [];

  constructor(
    private ingredientsService: IngredientService,
    private ingredientTypeService: IngredientTypeService
  ) {}
  getIngredients() {
    this.ingredientsService
      .getAllIngredients(localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
      .subscribe((res: ResultSetIngredientDtoNBK) => {
        this.ingredients$.next(res.value || []);
        this.ingredients = res.value || [];
      });
  }

  getFullIngredients(stepsIngr: StepIngredientDtoNBK[]) {
    let completeIngredients: IngredientBoDtoNBKComplete[] = [];

    stepsIngr.forEach((el) => {
      let ingr = this.ingredients.find((x) => x.id === el.ingredientId) || null;
      completeIngredients.push({
        ...ingr,
        um: el.um || '',
        quantity: el.quantity || 0
      });
    });

    return completeIngredients;
  }

  getTypeIngredients() {
    this.ingredientTypeService
      .getAllIngredientTypes(localStorage.getItem(KEY_LANGUAGE_STORAGE)!)
      .subscribe((res: ResultSetIngredientTypeDtoNBK) => {
        this.typeIngredients$.next(res.value || []);
        this.typeIngredients = res.value || [];
      });
  }
}
