import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { RecipeBoDtoNBK } from 'src/app/api/nbk';
import {
  IConfigSliderCookingStep,
  MAX_VALUE_TEMPERATURE,
  MIN_VALUE_TEMPERATURE,
  temperatureConfiguration
} from 'src/app/core/cooking-step.utils';
import { SectionTypeEnum } from 'src/app/core/utils';
import { RecipeDetailService } from '../../../recipe-detail.service';
import { ISectionList } from '../edit-cooking-steps-modal.component';

@Component({
  selector: 'app-phase-tab',
  templateUrl: './phase-tab.component.html',
  styleUrls: ['./phase-tab.component.scss']
})
export class PhaseTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() steam: boolean = false;
  @Input() fgroup: UntypedFormGroup;
  @Input() cookingMode: RecipeBoDtoNBK.CookingModeEnum;
  @Input() deviceModel: RecipeBoDtoNBK.DeviceModelEnum;
  @Input() sectionList: ISectionList[] = [];
  @Input() tabType: string;
  @Input() tempUnit: 'C' | 'F';
  @Input() firstPhase: boolean = false;

  @Output() initPreheat: EventEmitter<void> = new EventEmitter();

  selectedTemp: number = 0;
  selectedTime: number = 10;
  selectedAlternateFan: boolean = false;
  selectedVapor: number = 0;
  fanValue: number = 10;
  microValue: number = 0;
  tempValue: number = 30;
  timeValue: number = 10;

  sectionTypeList = SectionTypeEnum;

  get microwaveControl() {
    return this.fgroup.controls['magnetron'] as UntypedFormControl;
  }

  get tempControl() {
    return this.fgroup.controls['temperature'] as UntypedFormControl;
  }
  get tempDeltaControl() {
    return this.fgroup.controls['deltaT'] as UntypedFormControl;
  }
  get timeControl() {
    return this.fgroup.controls['time'] as UntypedFormControl;
  }
  get fanTypeControl() {
    return this.fgroup.controls['alternatingFan'] as UntypedFormControl;
  }
  get fanControl() {
    return this.fgroup.controls['fanSpeed'] as UntypedFormControl;
  }

  temperatureSetup: IConfigSliderCookingStep = {
    ceil: MAX_VALUE_TEMPERATURE,
    floor: MIN_VALUE_TEMPERATURE,
    minLimit: MIN_VALUE_TEMPERATURE
  };

  microwaveSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    firstStep: 15,
    minLimit: 0
  };

  timeSetup = {
    ceil: 36000,
    floor: 0,
    step: 5,
    time: true,
    firstStep: 10
  };

  fanSetup = {
    ceil: 100,
    floor: 0,
    step: 1,
    firstStep: 15
  };

  unsubscribe$: Subject<void> = new Subject();

  constructor(private recipeDetailService: RecipeDetailService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(): void {
    this.timeSetup.ceil = 3600;

    switch (this.tabType) {
      case 'CONVECTION':
        if (this.firstPhase) {
          this.recipeDetailService.enablePreheat();
          this.initPreheat.emit();
        }
        this.tempControl.enable();
        this.microwaveControl.disable();

        this.setTempValue(this.tempControl.value);
        this.setMicrowaveValue();

        break;
      case 'MICROWAVE':
        if (this.firstPhase) {
          this.recipeDetailService.disabledPreheat(
            this.cookingMode,
            this.tempUnit
          );
        }
        this.tempControl.disable();
        this.microwaveControl.enable();
        
        this.setTempValue();
        this.setMicrowaveValue(this.microwaveControl.value);

        break;
      case 'COMBO':
        if (this.firstPhase) {
          this.recipeDetailService.enablePreheat();
          this.initPreheat.emit();
        }
        this.tempControl.enable();
        this.microwaveControl.enable();
        
        this.setTempValue(this.tempControl.value);
        this.setMicrowaveValue(this.microwaveControl.value);

        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    this.setFanValue(this.fanControl.value);
    this.setTempValue(this.tempControl.value);
    this.setMicrowaveValue(this.microwaveControl.value);
    this.selectFan(this.fanTypeControl.value);
    this.setTimeValue(this.timeControl.value);
    this.temperatureSetup = temperatureConfiguration(this.tempUnit);
  }

  selectFan(sel: boolean) {
    this.selectedAlternateFan = sel;
    this.fanTypeControl.setValue(this.selectedAlternateFan);
  }

  setMicrowaveValue(number?: number) {
    this.microValue =
      this.microwaveControl.disabled || !number || number < this.microwaveSetup.minLimit!
        ? this.microwaveSetup.minLimit!
        : number;
    this.microwaveControl.setValue(this.microValue);
  }

  setTempValue(number?: number) {
    this.tempValue =
      this.tempControl.disabled || !number || number < this.temperatureSetup.minLimit!
        ? this.temperatureSetup.minLimit!
        : number;
    this.tempControl.setValue(this.tempValue);
  }

  setTimeValue(number: number) {
    this.timeValue = number;
    this.timeControl.setValue(this.timeValue);
  }

  setFanValue(number: number) {
    this.fanValue = number;
    this.fanControl.setValue(this.fanValue);
  }

  checkPhaseTab(sectionType: SectionTypeEnum) {
    return this.sectionList.some((s) => s.label === sectionType);
  }

  checkIfSliderDisabled(sectionType: SectionTypeEnum) {
    return this.sectionList.find((s) => s.label === sectionType)?.disabled!;
  }
}
