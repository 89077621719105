<div class="row filter-box pb-xl-4" [formGroup]="filterForm">
  <div class="col-12 col-xl-10">
    <div class="row">
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'FILTERS.TEXT' | translate }}
          <input
            type="text"
            class="custom-input"
            formControlName="name"
            [placeholder]="'RECIPES.FILTERS.PLACEHOLDER_NAME' | translate"
          />
        </label>
      </div>
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'FILTERS.TYPE' | translate }}
          <ng-select
            [items]="typeList"
            formControlName="type"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [placeholder]="'RECIPES.FILTERS.TYPE' | translate"
          ></ng-select>
        </label>
      </div>
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'FILTERS.COUNTRY' | translate }}
          <ng-select
            [items]="countryList"
            formControlName="country"
            [bindLabel]="'name'"
            [bindValue]="'code'"
            [placeholder]="'RECIPES.FILTERS.COUNTRY' | translate"
          ></ng-select>
        </label>
      </div>
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100 tags-filter">
          {{ 'RECIPE.LABELS' | translate }}
          <ng-select
            [items]="tags$ | async"
            bindLabel="name"
            [multiple]="true"
            [hideSelected]="true"
            [minTermLength]="2"
            typeToSearchText="{{ 'RECIPE.LABELS_PLACEHOLDER' | translate }}"
            [typeahead]="tagsInput$"
            formControlName="tags"
            [placeholder]="'RECIPES.LABELS_PLACEHOLDER' | translate"
          >
          </ng-select>
        </label>
      </div>
      <div class="col-12 col-xl-2 pt-3">
        <label class="custom-label w-100">
          {{ 'FILTERS.TIME_MINUTE' | translate }}
          <div class="combo-input">
            <input
              type="number"
              class="custom-input px-3"
              formControlName="timeFrom"
              [placeholder]="'RECIPES.FILTERS.TIME_MINUTE_FROM' | translate"
              min="0"
              [ngClass]="{ invalid: !timeFrom.valid }"
            />
            <input
              type="number"
              class="custom-input px-3"
              formControlName="timeTo"
              [placeholder]="'RECIPES.FILTERS.TIME_MINUTE_TO' | translate"
              min="0"
              [ngClass]="{ invalid: !timeTo.valid }"
            />
          </div>
        </label>
      </div>
      <div class="col-12 col-xl-2 pt-3">
        <label class="custom-label w-100">
          {{ 'FILTERS.CALORIES' | translate }}
          <div class="combo-input">
            <input
              type="number"
              class="custom-input px-3"
              formControlName="caloriesFrom"
              [placeholder]="'RECIPES.FILTERS.CALORIES_FROM' | translate"
              min="0"
              [ngClass]="{ invalid: !caloriesFrom.valid }"
            />
            <input
              type="number"
              class="custom-input px-3"
              formControlName="caloriesTo"
              [placeholder]="'RECIPES.FILTERS.CALORIES_TO' | translate"
              min="0"
              [ngClass]="{ invalid: !caloriesTo.valid }"
            />
          </div>
        </label>
      </div>
      <div class="col-12 col-xl-2 pt-3 align-content-center" *ngIf="!isIcs">
        <label class="custom-label w-100">
          <input type="checkbox" formControlName="noIngredients" />
          {{ 'FILTERS.NO_INGREDIENTS' | translate }}
        </label>
      </div>
      <div class="col-12 col-xl-2 pt-3 align-content-center" *ngIf="!isIcs">
        <label class="custom-label w-100">
          <input type="checkbox" formControlName="noCookingStep" />
          {{ 'FILTERS.NO_COOKING_STEP' | translate }}
        </label>
      </div>
      <div class="col-12 col-xl-2 pt-3 align-content-center" *ngIf="!isIcs">
        <label class="custom-label w-100">
          <input type="checkbox" formControlName="onlyPersonal" />
          {{ 'FILTERS.ONLY_PERSONAL' | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="col-12 col-xl-2">
    <div class="filter-button-box">
      <button
        class="button-primary mb-2"
        (click)="search()"
        [disabled]="!filterForm.valid"
      >
        <i class="fa fa-fw fa-search"></i>{{ 'GLOBAL.FIND' | translate }}
      </button>
      <button class="button-secondary mb-2" (click)="clean()">
        <i class="fa fa-fw fa-undo"></i>{{ 'GLOBAL.CLEAN' | translate }}
      </button>
    </div>
  </div>
</div>
