import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export type TDeviceWizardType = 'SERIAL' | 'SECURE_CODE';

export enum DeviceWizardTypeEnum {
  serial = 'SERIAL',
  secureCode = 'SECURE_CODE'
}

export interface IPairDevice {
  serialNumber: string;
  secureCode: string;
}

export const secureCodeDeviceLength = 4;

export const INFO_DEVICE_STORAGE_KEY = 'LAINOX_NABOOK_DEVICE_INFO';

export enum InfoDeviceEnum {
  // icon = 'ICON',
  name = 'NAME',
  serial = 'SERIAL',
  hardware = 'HARDWARE',
  firmware = 'FIRMWARE',
  sync = 'SYNC',
  date = 'DATE',
  model = 'MODEL',
  display = 'DISPALY'
}

export const infoDevice = [
  // {
  //   key: InfoDeviceEnum.icon,
  //   label: _('DEVICE.INFO.ICON'),
  //   defaultValue: true
  // },
  {
    key: InfoDeviceEnum.serial,
    label: _('DEVICE.INFO.SERIAL'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.name,
    label: _('DEVICE.INFO.NAME'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.hardware,
    label: _('DEVICE.INFO.HARDWARE'),
    defaultValue: false
  },
  {
    key: InfoDeviceEnum.firmware,
    label: _('DEVICE.INFO.FIRMWARE'),
    defaultValue: false
  },
  {
    key: InfoDeviceEnum.sync,
    label: _('DEVICE.INFO.SYNC'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.date,
    label: _('DEVICE.INFO.DATE'),
    defaultValue: false
  },
  {
    key: InfoDeviceEnum.model,
    label: _('DEVICE.INFO.MODEL'),
    defaultValue: true
  },
  {
    key: InfoDeviceEnum.display,
    label: _('MY_DISPLAY.DISPLAY'),
    defaultValue: false
  }
];

export const KeyTelemetryCSV = {
  Date: _('DEVICE.TELEMETRY.CSV.HEADERS.Date'),
  Device: _('DEVICE.TELEMETRY.CSV.HEADERS.Device'),
  Event: _('DEVICE.TELEMETRY.CSV.HEADERS.Event'),
  OvenState: _('DEVICE.TELEMETRY.CSV.HEADERS.OvenState'),
  OvenType: _('DEVICE.TELEMETRY.CSV.HEADERS.OvenType'),
  ModoCottura: _('DEVICE.TELEMETRY.CSV.HEADERS.ModoCottura'),
  ProbeRoom: _('DEVICE.TELEMETRY.CSV.HEADERS.ProbeRoom'),
  UmTemperatura: _('DEVICE.TELEMETRY.CSV.HEADERS.UmTemperatura'),
  SetCamera: _('DEVICE.TELEMETRY.CSV.HEADERS.SetCamera'),
  SetPower: _('DEVICE.TELEMETRY.CSV.HEADERS.SetPower'),
  SetTimerSeconds: _('DEVICE.TELEMETRY.CSV.HEADERS.SetTimerSeconds'),
  SetVentole: _('DEVICE.TELEMETRY.CSV.HEADERS.SetVentole'),
  DiAC_TCMagnetron1: _('DEVICE.TELEMETRY.CSV.HEADERS.DiAC_TCMagnetron1'),
  DiAC_TCMagnetron2: _('DEVICE.TELEMETRY.CSV.HEADERS.DiAC_TCMagnetron2'),
  DiConsensoMagnetron: _('DEVICE.TELEMETRY.CSV.HEADERS.DiConsensoMagnetron'),
  DiDoor: _('DEVICE.TELEMETRY.CSV.HEADERS.DiDoor'),
  DiTermicaCamera: _('DEVICE.TELEMETRY.CSV.HEADERS.DiTermicaCamera'),
  FrequenzaFaseNominale: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.FrequenzaFaseNominale'
  ),
  M1State: _('DEVICE.TELEMETRY.CSV.HEADERS.M1State'),
  M2State: _('DEVICE.TELEMETRY.CSV.HEADERS.M2State'),
  PercHeater: _('DEVICE.TELEMETRY.CSV.HEADERS.PercHeater'),
  ProbeRoomReal: _('DEVICE.TELEMETRY.CSV.HEADERS.ProbeRoomReal'),
  ProbeVano: _('DEVICE.TELEMETRY.CSV.HEADERS.ProbeVano'),
  PwmMotore: _('DEVICE.TELEMETRY.CSV.HEADERS.PwmMotore'),
  RecId: _('DEVICE.TELEMETRY.CSV.HEADERS.RecId'),
  RecType: _('DEVICE.TELEMETRY.CSV.HEADERS.RecType'),
  RecValue: _('DEVICE.TELEMETRY.CSV.HEADERS.RecValue'),
  RpmMotore: _('DEVICE.TELEMETRY.CSV.HEADERS.RpmMotore'),
  VentolaRaffOn: _('DEVICE.TELEMETRY.CSV.HEADERS.VentolaRaffOn'),
  RecInfo: _('DEVICE.TELEMETRY.CSV.HEADERS.RecInfo'),
  RecipeId: _('DEVICE.TELEMETRY.CSV.HEADERS.RecipeId'),
  RecInfoString: _('DEVICE.TELEMETRY.CSV.HEADERS.RecInfoString'),
  combiOperatingHoursVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.combiOperatingHoursVal'
  ),
  combiStndbyVal: _('DEVICE.TELEMETRY.CSV.HEADERS.combiStndbyVal'),
  coolingOperatingTimeVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.coolingOperatingTimeVal'
  ),
  fanOperatingTimeVal: _('DEVICE.TELEMETRY.CSV.HEADERS.fanOperatingTimeVal'),
  heatingOperatingTimeVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.heatingOperatingTimeVal'
  ),
  hibernateStndbyVal: _('DEVICE.TELEMETRY.CSV.HEADERS.hibernateStndbyVal'),
  hsoOperatingHourVal: _('DEVICE.TELEMETRY.CSV.HEADERS.hsoOperatingHourVal'),
  hsoStndbyVal: _('DEVICE.TELEMETRY.CSV.HEADERS.hsoStndbyVal'),
  mag1Val: _('DEVICE.TELEMETRY.CSV.HEADERS.mag1Val'),
  mag2Val: _('DEVICE.TELEMETRY.CSV.HEADERS.mag2Val'),
  numeroAperturePortaVal: _(
    'DEVICE.TELEMETRY.CSV.HEADERS.numeroAperturePortaVal'
  ),
  oreSchedaVal: _('DEVICE.TELEMETRY.CSV.HEADERS.oreSchedaVal')
};

export const pollingTime = 300;

export const showDeviceInfo = (key: InfoDeviceEnum) => {
  const infoDeviceVisible = JSON.parse(
    localStorage.getItem(INFO_DEVICE_STORAGE_KEY)!
  );
  return infoDeviceVisible.includes(key);
}
